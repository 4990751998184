import React from 'react';
import { ResponsivePieCanvas, ResponsivePie } from '@nivo/pie'

function PieGraph({
    graphTitle,
    graphValueA,
    graphValueB,
    graphValueC,
    graphValueTotal,
    graphLabelValueA,
    graphLabelValueB,
    graphLabelValueC,
    graphWidth,
    graphHeight,
    mobileGraph,
    metricSubTitle,
    alternateCenterMetricPercent
}) {

    // convert passed-in reference variables to ensure proper Number format for Graph library operation
    const valueA = Number(graphValueA)
    const valueB = Number(graphValueB)
    const valueC = Number(graphValueC)

    const nivoPieGraphData = [
        {
            "id": graphLabelValueA,
            "label": graphLabelValueA,
            "value": valueA,
            "color": "rgb(35, 84, 159)"
        },
        {
            "id": graphLabelValueB,
            "label": graphLabelValueB,
            "value": valueB,
            "color": "rgb(194, 237, 229)"
        },
        {
            "id": graphLabelValueC,
            "label": graphLabelValueC,
            "value": valueC,
            "color": "rgb(237,192, 65)"
        },
    ]

    const CenteredMetric = ({ dataWithArc, centerX, centerY }) => {
        let total = 0
        dataWithArc.forEach(datum => {
            total += datum.value
        })

        return (<>
            <text
                x={centerX}
                y={centerY - 10}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '20px',
                    fontWeight: 600,
                  
                    
                }}
            >

                {alternateCenterMetricPercent ? (`${alternateCenterMetricPercent}%`) : (total?.toLocaleString())}

            </text>
            {metricSubTitle == 'Response Rate' ? (
                <>
                <text
                x={centerX}
                y={centerY + 10}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '12px',
                    fontWeight: 600,
                    overflowWrap: 'break-word',
                    whiteSpace: 'break-spaces'
                }}
            >
                Response 
               
            </text >
            <text
                x={centerX}
                y={centerY + 25}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '12px',
                    fontWeight: 600,
                    overflowWrap: 'break-word',
                    whiteSpace: 'break-spaces'
                }}
            >
                Rate 
               
                    </text >
            </>
            ) : (<text
                x = { centerX }
                y = {centerY + 10}
            textAnchor="middle"
            dominantBaseline="central"
            style={{
                fontSize: '12px',
                fontWeight: 600,
                overflowWrap: 'break-word',
                whiteSpace: 'break-spaces'
            }}
            >
            {metricSubTitle}
        </text >) }
          
        </>

        )
    }


    return (
        <>
            {mobileGraph ? (<>
                <div role="img" aria-label={graphTitle}>
                    <div style={{ width: '200px', height: '200px' }} aria-hidden="true">
                        {/*  <span className='report-graphcard-title report-text-content-font bold'>{graphTitle}</span>*/}

                        <ResponsivePie
                            alt={graphTitle}
                            aria-label={graphTitle}
                            data={nivoPieGraphData}
                            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                            layers={['arcs', CenteredMetric]}
                            innerRadius={0.75}
                            padAngle={2.5}
                            activeOuterRadiusOffset={5}
                            valueFormat={value => (`${Number(value)?.toLocaleString()}`)}
                          
                            colors={['#23549F', '#C2EDE5', '#EDC041']}
                            borderColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        0.6
                                    ]
                                ]
                            }}
                            
                            arcLinkLabelsSkipAngle={10}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            arcLabelsSkipAngle={10}
                            arcLabelsTextColor={{
                                from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        2
                                    ]
                                ]
                            }}
                            enableArcLinkLabels={false}
                            arcLinkLabelsSkipAngle={0}
                            arcLinkLabelsTextColor="#333333"
                            arcLinkLabelsThickness={2}
                            arcLinkLabelsColor={{ from: 'color' }}
                            enableArcLabels={false}
                            arcLabelsRadiusOffset={0.5}
                            arcLabelsTextColor="#333333"
                            isInteractive={true}
                            title={graphTitle}

                            fill={[
                            ]}
                            legends={[
                                //{
                                //    anchor: 'bottom',
                                //    direction: 'row',
                                //    justify: false,
                                //    translateX: 0,
                                //    translateY: 40,
                                //    itemsSpacing: 10,
                                //    itemWidth: 50,
                                //    itemHeight: 18,
                                //    itemTextColor: '#999',
                                //    itemDirection: 'left-to-right',
                                //    itemOpacity: 1,
                                //    symbolSize: 10,
                                //    symbolShape: 'circle',
                                //    effects: [
                                //        {
                                //            on: 'hover',
                                //            style: {
                                //                itemTextColor: '#000'
                                //            }
                                //        }
                                //    ]
                                //}
                            ]}
                        />

                    </div>
                </div>



            </>) : (<>
                    <div role="img" aria-label={graphTitle}>
                    <div style={{ width: '200px', height: '200px', marginLeft: '3.75rem' }} aria-hidden="true">
                    {/*  <span className='report-graphcard-title report-text-content-font bold'>{graphTitle}</span>*/}

                    <ResponsivePie
                        data={nivoPieGraphData}
                        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                        layers={['arcs', CenteredMetric]}
                        innerRadius={0.75}
                        padAngle={2.5}
                        activeOuterRadiusOffset={5}
                        colors={['#23549F', '#C2EDE5', '#EDC041']}
                        borderColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    0.6
                                ]
                            ]
                        }}
                        valueFormat={value => (`${Number(value)?.toLocaleString()}`)}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        arcLabelsSkipAngle={10}
                        arcLabelsTextColor={{
                            from: 'color',
                            modifiers: [
                                [
                                    'darker',
                                    2
                                ]
                            ]
                        }}
                        enableArcLinkLabels={false}
                        arcLinkLabelsSkipAngle={0}
                        arcLinkLabelsTextColor="#333333"
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{ from: 'color' }}
                        enableArcLabels={true}
                        arcLabelsRadiusOffset={0.5}
                        arcLabelsTextColor="#333333"
                        isInteractive={true}
                        title={graphTitle}

                        fill={[
                        ]}
                        legends={[
                            //{
                            //    anchor: 'bottom',
                            //    direction: 'row',
                            //    justify: false,
                            //    translateX: 0,
                            //    translateY: 56,
                            //    itemsSpacing: 0,
                            //    itemWidth: 100,
                            //    itemHeight: 18,
                            //    itemTextColor: '#999',
                            //    itemDirection: 'left-to-right',
                            //    itemOpacity: 1,
                            //    symbolSize: 18,
                            //    symbolShape: 'circle',
                            //    effects: [
                            //        {
                            //            on: 'hover',
                            //            style: {
                            //                itemTextColor: '#000'
                            //            }
                            //        }
                            //    ]
                            //}
                        ]}
                    />

                </div>
                </div>
            </>)}
        </>
    );
}

export default PieGraph;

