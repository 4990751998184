import React, { useState, useEffect, PureComponent, useContext, useCallback, useRef } from 'react';
import { useParams, useNavigate, Navigate, Link, redirect } from "react-router-dom";
import { Bar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveCalendar } from '@nivo/calendar';
import { BsCheckLg, BsXLg, BsExclamationCircleFill } from 'react-icons/bs';


import Box from '@mui/material/Box';
import Container from "@mui/material/Container";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import ProjectCard from "../components/cards/ProjectCard";
import ProjectTable from "../components/tables/ProjectTable";
import MultiBarChart from "../components/graphs/MultiBarChart";
import Tooltip from "@mui/material/Tooltip";
import GraphCard from "../components/cards/GraphCard";
import PieGraph from "../components/graphs/PieGraph";
import IneligibleReasonBarChart from "../components/graphs/IneligibleReasonBarChart";
import IneligibleExtendedGraphCard from "../components/cards/IneligibleExtendedGraphCard";
import { RolesContext, SidebarContext } from "../App";
import GraphLegendCard from '../components/cards/GraphLegendCard';
import PercentageBarChart from "../components/graphs/PercentageBarChart";
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import { RoutesContext, PermissionsContext, GraphDetailsContext, ProjectDashboardContext, RenderedProjectDashboardContext } from "../App";
import Button from '@mui/material/Button';
import { ExportToCsv } from 'export-to-csv';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { loginRequest, b2cPolicies } from "../authConfig";
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import useFetch from "../hooks/useFetch";
import Badge from '@mui/material/Badge';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import useFilterReport from "../hooks/useFilterReport";
import useFetchGraphDetails from "../hooks/useFetchGraphDetails";
import useFilterGraph from "../hooks/useFilterGraph";
import AuditEndDateMenu from '../components/menus/AuditEndDateMenu';






//project page
function Project({ onChange, children }) {

    //const [responseRateData, setResponseRateData] = useState()
    //const [employeeStatusData, setEmployeeStatusData] = useState()
    //const [dependentStatusData, setDependentStatusData] = useState()
    //const [ineligibleStatusData, setIneligibleStatusData] = useState()
    //const [incompleteStatusData, setIncompleteStatusData] = useState()

  
    //setRenderedMemberStatusReport(filterMembersReport.handleFiltering());

    const {
        setSidebarTitle,
        setSidebarActions,
        sidebarActiveClientID,
        setSidebarActiveClientID
    } = useContext(SidebarContext);

    const { graphComponentData,
        handleUpdateGraphComponentData,
        graphTableComponentData,
        handleUpdateGraphTableComponentData } = useContext(GraphDetailsContext);

    const {
        projectId, setProjectId,
        familyReport, setFamilyReport,
        dependentReport, setDependentReport,
        alertReport, setAlertReport,
        ineligibleReport, setIneligibleReport,
        responseRateData, setResponseRateData,
        employeeStatusData, setEmployeeStatusData,
        dependentStatusData, setDependentStatusData,
        ineligibleStatusData, setIneligibleStatusData,
        incompleteStatusData, setIncompleteStatusData,
        incompleteReport, setIncompleteReport,
        finalIneligibleList, setFinalIneligibleList,
        alertNoteCount, setAlertNoteCount,
        firstIneligibleList, setFirstIneligibleList,
        secIneligibleList, setSecIneligibleList,
        extendIneligibleGraphContainer, setExtendIneligibleGraphContainer,
        hideIneligibleChart, setHideIneligibleChart,
        showIneligibleChart, setShowIneligibleChart,
        memberReport, setMemberReport,
        memberStatusData, setMemberStatusData,
        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates, callFilter, setCallFilter,
        unfilteredFamilyReport, setUnfilteredFamilyReport,
        unfilteredDependentReport, setUnfilteredDependentReport,
        unfilteredAlertReport, setUnfilteredAlertReport,
        unfilteredIneligibleReport, setUnfilteredIneligibleReport,
        unfilteredMemberReport, setUnfilteredMemberReport,
        unfilteredResponseRateData, setUnfilteredResponseRateData,
        unfilteredEmployeeStatusData, setUnfilteredEmployeeStatusData,
        unfilteredDependentStatusData, setUnfilteredDependentStatusData,
        unfilteredIneligibleStatusData, setUnfilteredIneligibleStatusData,
        unfilteredIncompleteStatusData, setUnfilteredIncompleteStatusData,
        unfilteredMemberStatusData, setUnfilteredMemberStatusData,
        unfilteredIncompleteReport, setUnfilteredIncompleteReport,
        unfilteredAlertNoteCount, setUnfilteredAlertNoteCount
    } = useContext(ProjectDashboardContext);

    const {

        renderedFamilyReport, setRenderedFamilyReport,
        renderedDependentReport, setRenderedDependentReport,
        renderedAlertReport, setRenderedAlertReport,
        renderedIneligibleReport, setRenderedIneligibleReport,
        renderedMemberReport, setRenderedMemberReport,
        renderedResponseRateData, setRenderedResponseRateData,
        renderedEmployeeStatusData, setRenderedEmployeeStatusData,
        renderedDependentStatusData, setRenderedDependentStatusData,
        renderedIneligibleStatusData, setRenderedIneligibleStatusData,
        renderedIncompleteStatusData, setRenderedIncompleteStatusData,
        renderedMemberStatusData, setRenderedMemberStatusData,
        renderedIncompleteReport, setRenderedIncompleteReport,
        renderedFinalIneligibleList, setRenderedFinalIneligibleList,
        showRenderedIneligibleChart, setShowRenderedIneligibleChart,
        extendRenderedIneligibleGraphContainer, setExtendRenderedIneligibleGraphContainer,
        renderedAlertNoteCount, setRenderedAlertNoteCount,
        renderedFirstIneligibleList, setRenderedFirstIneligibleList,
        renderedSecIneligibleList, setRenderedSecIneligibleList,
        hideRenderedIneligibleChart, setRenderedHideIneligibleChart,
        filteredAuditDates, setFilteredAuditDates,
        familyCardData, setFamilyCardData,
        depCardData, setDepCardData,
        memberCardData, setMemberCardData,
        responseCardData, setResponseCardData,
        ineligibleCardData, setIneligibleCardData,
        incompleteCardData, setIncompleteCardData,


    } = useContext(RenderedProjectDashboardContext);


    const [isReportRendering, setIsReportRendering] = useState(true);


    const filterFamHook = useFilterReport();
    const filterDepHook = useFilterReport();
    const filterAlertHook = useFilterReport();
    const filterIneligibleHook = useFilterReport();
    const filterIncompleteHook = useFilterReport();
   // const filterMemberHook = useFilterReport();

    const filterFamGraphHook = useFilterGraph();
    const filterDepGraphHook = useFilterGraph();
    const filterIneligibleGraphHook = useFilterGraph();
    const filterIncompleteGraphHook = useFilterGraph();
    //const filterMemberGraphHook = useFilterGraph();

    //Card data for each graph 
    //const [familyCardData, setFamilyCardData] = useState([]);
    //const [depCardData, setDepCardData] = useState([]);
    //const [memberCardData, setMemberCardData] = useState([]);
    //const [responseCardData, setResponseCardData] = useState([]);
    //const [ineligibleCardData, setIneligibleCardData] = useState([]);
    //const [incompleteCardData, setIncompleteCardData] = useState([]);

    useEffect(() => {
        console.log("hit filter hook funciton call -RenderedFam - ", renderedFamilyReport);
        console.log("hit filter hook funciton call -RenderedDep - ", renderedDependentReport);
        console.log("hit filter hook funciton call -RenderedInel - ", renderedIneligibleReport);
        console.log("hit filter hook funciton call -RenderedIncompl - ", renderedIncompleteReport);
        console.log("hit filter hook funciton call -RenderedMember - ", renderedMemberReport);
        console.log("hit filter hook funciton call -RenderedAlert - ", renderedAlertReport);
        console.log("hit filter hook funciton call -unfilteredFam - ", unfilteredFamilyReport);
        console.log("hit filter hook funciton call -unfilteredDep - ", unfilteredDependentReport);
        console.log("hit filter hook funciton call -unfilteredInel - ", unfilteredIneligibleReport);
        console.log("hit filter hook funciton call -unfilteredIncompl - ", unfilteredIncompleteReport);
        console.log("hit filter hook funciton call -unfilteredMember - ", unfilteredMemberReport);
        console.log("hit filter hook funciton call -unfilteredAlert - ", unfilteredAlertReport);


        
        console.log("hit filter hook funciton call - ", activeClientAuditEndDates);

        if (activeClientAuditEndDates[0]?.auditEndDate === "12/12/1800" && familyReport !== undefined && dependentReport !== undefined && alertReport !== undefined && ineligibleReport !== undefined && memberReport !== undefined && incompleteReport !== undefined) { //if all is selected, set renderedReports/graph data to corresponding reports from projectDashboardContext (i.e. full, non-filtered reports will be rendered out)
                //all reports rendered + alert note count
                console.log("hit internal filter call");
            setRenderedFamilyReport(unfilteredFamilyReport)
            setFamilyReport(unfilteredFamilyReport)

            setRenderedDependentReport(unfilteredDependentReport)
            setDependentReport(unfilteredDependentReport)
         
            setRenderedIneligibleReport(unfilteredIneligibleReport)
            setIneligibleReport(unfilteredIneligibleReport)
          
            //setRenderedMemberReport(unfilteredMemberReport)
            //setMemberReport(unfilteredMemberReport)
          
            setRenderedIncompleteReport(unfilteredIncompleteReport)
            setIncompleteReport(unfilteredIncompleteReport)
          
            setRenderedAlertReport(unfilteredAlertReport)
            setAlertReport(unfilteredAlertReport)

            setRenderedAlertNoteCount(unfilteredAlertNoteCount)
            setAlertNoteCount(unfilteredAlertNoteCount)
            filterFamGraphHook.handleFamilyGraphFiltering(unfilteredFamilyReport);
            filterDepGraphHook.handleDependentGraphFiltering(unfilteredDependentReport);
            filterIneligibleGraphHook.handleIneligibleGraphFiltering(unfilteredIneligibleReport);
            filterIncompleteGraphHook.handleIncompleteGraphFiltering(unfilteredIncompleteReport);
            //filterMemberGraphHook.handleMemberGraphFiltering(unfilteredMemberReport);

            if (unfilteredFamilyReport?.length > 0 || unfilteredAlertReport?.length > 0 || unfilteredDependentReport?.length > 0 || unfilteredIneligibleReport?.length > 0 || unfilteredIncompleteReport?.length > 0 || unfilteredMemberReport?.length > 0) {
                console.log("hit promise is loading in callFilter, all - ", promiseIsLoading);
                setPromiseIsLoading(false);
            }
           

           
            setIsReportRendering(false);
               

        }
            if (activeClientAuditEndDates && activeClientAuditEndDates[0]?.auditEndDate !== "12/12/1800" && familyReport !== undefined && dependentReport !== undefined && alertReport !== undefined && ineligibleReport !== undefined && memberReport !== undefined && incompleteReport !== undefined) {

                if (familyReport && unfilteredFamilyReport?.length > 0 && activeClientAuditEndDates && activeClientAuditEndDates[0]?.auditEndDate !== "12/12/1800") {
                    //filterFamily(familyReport, activeClientAuditEndDates);
                    filterFamHook.handleFiltering(unfilteredFamilyReport, activeClientAuditEndDates);
                   
                 
              
                }
                if (dependentReport && unfilteredDependentReport?.length > 0 && activeClientAuditEndDates && activeClientAuditEndDates[0]?.auditEndDate !== "12/12/1800") {
                    //filterDependent(dependentReport, activeClientAuditEndDates);
                    filterDepHook.handleFiltering(unfilteredDependentReport, activeClientAuditEndDates);
                    //console.log("dependent filter -- ", filterDependentData);

                }
                if (alertReport && unfilteredAlertReport?.length > 0 && activeClientAuditEndDates && activeClientAuditEndDates[0]?.auditEndDate !== "12/12/1800") {
                    //filterAlert(alertReport, activeClientAuditEndDates);
                    //console.log("alert filter -- ", filterAlertData);
                    filterAlertHook.handleFiltering(unfilteredAlertReport, activeClientAuditEndDates);
                 
                }
                if (ineligibleReport && unfilteredIneligibleReport?.length > 0 && activeClientAuditEndDates && activeClientAuditEndDates[0]?.auditEndDate !== "12/12/1800") {
                    //filterIneligible(ineligibleReport, activeClientAuditEndDates);
                    //console.log("ineligible filter -- ", filterIneligibleData);
                    filterIneligibleHook.handleFiltering(unfilteredIneligibleReport, activeClientAuditEndDates);
                  
                }
                if (incompleteReport && unfilteredIncompleteReport?.length > 0 && activeClientAuditEndDates && activeClientAuditEndDates[0]?.auditEndDate !== "12/12/1800") {
                    //filterIncomplete(incompleteReport, activeClientAuditEndDates);
                    //console.log("incomplete filter -- ", filterIncompleteData);
                    filterIncompleteHook.handleFiltering(unfilteredIncompleteReport, activeClientAuditEndDates);
                   
                }
                //if (memberReport && unfilteredMemberReport?.length > 0 && activeClientAuditEndDates && activeClientAuditEndDates[0]?.auditEndDate !== "12/12/1800") {
                //    //filterMember(memberReport, activeClientAuditEndDates);
                //    //console.log("member filter -- ", filterMemberData);
                //    filterMemberHook.handleFiltering(unfilteredMemberReport, activeClientAuditEndDates);

                //}
                setIsReportRendering(false);
                //setCallFilter(false);

            }

        
      
     

    }, [callFilter, setCallFilter]);


    //look here for filtered graph details data

    useEffect(() => {
        console.log("rendered family status", filterFamHook.filteredData);

        if (activeClientAuditEndDates[0]?.auditEndDate == "12/12/1800") {
            console.log("setting family report with rendered family status unfiltered ", filterFamHook.filteredData);
            setFamilyReport(unfilteredFamilyReport);
            setRenderedFamilyReport(unfilteredFamilyReport);
            filterFamGraphHook.handleFamilyGraphFiltering(filterFamHook.filteredData);

        } else {
            console.log("setting family report with rendered family status else ", filterFamHook.filteredData);
            setFamilyReport(filterFamHook.filteredData);
            setFamilyReport(filterFamHook.filteredData);
            setRenderedFamilyReport(filterFamHook.filteredData);
            filterFamGraphHook.handleFamilyGraphFiltering(filterFamHook.filteredData);
        }

        if (filterFamHook.filteredData !== null && filterFamHook.filteredData.length > 0) {
            console.log("setting family report with rendered family status", filterFamHook.filteredData);
            setFamilyReport(filterFamHook.filteredData);
            setRenderedFamilyReport(filterFamHook.filteredData);
            filterFamGraphHook.handleFamilyGraphFiltering(filterFamHook.filteredData);
        }
      
    }, [filterFamHook.filteredData]);

    useEffect(() => {
        console.log("rendered dependent status ", filterDepHook.filteredData);

        if (activeClientAuditEndDates[0]?.auditEndDate == "12/12/1800") {
            setDependentReport(unfilteredDependentReport);
            setRenderedDependentReport(unfilteredDependentReport);
            filterDepGraphHook.handleDependentGraphFiltering(filterDepHook.filteredData)
        } else {
            setDependentReport(filterDepHook.filteredData);
            setRenderedDependentReport(filterDepHook.filteredData);
            filterDepGraphHook.handleDependentGraphFiltering(filterDepHook.filteredData)
        }
        
        if (filterDepHook.filteredData !== null && filterDepHook.filteredData.length > 0) {
            console.log("setting setDependentReport with rendered dependent status", filterDepHook.filteredData);
            setDependentReport(filterDepHook.filteredData);
            setRenderedDependentReport(filterDepHook.filteredData);
            filterDepGraphHook.handleDependentGraphFiltering(filterDepHook.filteredData)
        }
      
    }, [filterDepHook.filteredData]);

    useEffect(() => {
        console.log("rendered ineligible reason ", filterIneligibleHook.filteredData);

        if (activeClientAuditEndDates[0]?.auditEndDate == "12/12/1800") {
            setIneligibleReport(unfilteredIneligibleReport);
            setRenderedIneligibleReport(unfilteredIneligibleReport);
            filterIneligibleGraphHook.handleIneligibleGraphFiltering(filterIneligibleHook.filteredData);
        } else {
            setIneligibleReport(filterIneligibleHook.filteredData);
            setRenderedIneligibleReport(filterIneligibleHook.filteredData);
            filterIneligibleGraphHook.handleIneligibleGraphFiltering(filterIneligibleHook.filteredData);
        }
       
        if (filterIneligibleHook.filteredData !== null && filterIneligibleHook.filteredData.length > 0) {
            console.log("setting filterIneligibleHook.filteredData with rendered inelgibile reason", filterIneligibleHook.filteredData);
            setIneligibleReport(filterIneligibleHook.filteredData);
            setRenderedIneligibleReport(filterIneligibleHook.filteredData);
            filterIneligibleGraphHook.handleIneligibleGraphFiltering(filterIneligibleHook.filteredData);
        }
        
    }, [filterIneligibleHook.filteredData]);

    useEffect(() => {
        console.log("rendered incomplete reason ", filterIncompleteHook.filteredData);

        if (activeClientAuditEndDates[0]?.auditEndDate == "12/12/1800") {
            setIncompleteReport(unfilteredIncompleteReport);
            setRenderedIncompleteReport(unfilteredIncompleteReport);
            filterIncompleteGraphHook.handleIncompleteGraphFiltering(filterIncompleteHook.filteredData);
        } else {
            setIncompleteReport(filterIncompleteHook.filteredData);
            setRenderedIncompleteReport(filterIncompleteHook.filteredData);
            filterIncompleteGraphHook.handleIncompleteGraphFiltering(filterIncompleteHook.filteredData);
        }

        if (filterIncompleteHook.filteredData !== null && filterIncompleteHook.filteredData.length > 0) {
            console.log("setting filterIncompleteHook.filteredData with rendered incomplete reason", filterIncompleteHook.filteredData);
            setIncompleteReport(filterIncompleteHook.filteredData);
            setRenderedIncompleteReport(filterIncompleteHook.filteredData);
            filterIncompleteGraphHook.handleIncompleteGraphFiltering(filterIncompleteHook.filteredData);
        }
       
    }, [filterIncompleteHook.filteredData]);


    //useEffect(() => {
    //    console.log("rendered member status ", renderedMemberReport);

    //    if (activeClientAuditEndDates[0]?.auditEndDate == "12/12/1800") {
    //        setMemberReport(unfilteredMemberReport);
    //        setRenderedMemberReport(unfilteredMemberReport);
    //        filterMemberGraphHook.handleMemberGraphFiltering(filterMemberHook.filteredData);
    //    } else {
    //        setMemberReport(filterMemberHook.filteredData);
    //        setRenderedMemberReport(filterMemberHook.filteredData);
    //        filterMemberGraphHook.handleMemberGraphFiltering(filterMemberHook.filteredData);
    //    }

    //    if (filterMemberHook.filteredData !== null && filterMemberHook.filteredData.length > 0) {
    //        console.log("setting filterMemberHook.filteredData with rendered member status", filterMemberHook.filteredData);
    //        setMemberReport(filterMemberHook.filteredData);
    //        setRenderedMemberReport(filterMemberHook.filteredData);
    //        filterMemberGraphHook.handleMemberGraphFiltering(filterMemberHook.filteredData);
    //    }
        
    //}, [filterMemberHook.filteredData]);

    useEffect(() => {
        console.log("rendered Alert Report ", renderedAlertReport);
        console.log("rendered Alert Length (count) ", renderedAlertNoteCount);

        console.log("filtered Alert Report ", filterAlertHook.filteredData);
        console.log("filtered Alert Length (count) ", filterAlertHook.filteredData.length);

        if (activeClientAuditEndDates[0]?.auditEndDate == "12/12/1800") {

            setAlertReport(unfilteredAlertReport);
            setAlertNoteCount(unfilteredAlertNoteCount)
            setRenderedAlertReport(unfilteredAlertReport);
            setRenderedAlertNoteCount(unfilteredAlertNoteCount);

        } else {
            setAlertReport(filterAlertHook.filteredData);
            setAlertNoteCount(filterAlertHook.filteredData.length)

            setRenderedAlertReport(filterAlertHook.filteredData);
            setRenderedAlertNoteCount(filterAlertHook.filteredData.length);
        }

         if (filterAlertHook.filteredData !== null && filterAlertHook.filteredData.length !== 0) {
            console.log("setting filterMemberHook.filteredData with rendered member status", filterAlertHook.filteredData);
            setAlertReport(filterAlertHook.filteredData);
            setAlertNoteCount(filterAlertHook.filteredData.length)

            setRenderedAlertReport(filterAlertHook.filteredData);
            setRenderedAlertNoteCount(filterAlertHook.filteredData.length);
        }

    }, [filterAlertHook.filteredData]);

    /* set recalculated graphs to render */
    useEffect(() => {
        console.log("filtered fam graph data - ", filterFamGraphHook.filteredGraphData, filterFamGraphHook.filteredGraphCardData);
        console.log("filtered fam graphd data legend variable - ", filterFamGraphHook.filteredGraphCardData[2]?.ESCompleteTotal);
        /*if (filterFamGraphHook.filteredGraphCardData !== null && filterFamGraphHook.filteredGraphCardData !== undefined && filterFamGraphHook.filteredGraphData !== null && filterFamGraphHook.filterGraphData !== undefined) {*/
            console.log("filtered fam graphd data legend variable set called - ", filterFamGraphHook.filteredGraphCardData[2]?.ESCompleteTotal);
            setRenderedEmployeeStatusData(filterFamGraphHook.filteredGraphData[0]);
            setRenderedResponseRateData(filterFamGraphHook.filteredGraphData[1]);
            setFamilyCardData(filterFamGraphHook.filteredGraphCardData);
            setResponseCardData(filterFamGraphHook.filteredGraphCardData);

        //}
       
    }, [filterFamGraphHook.filteredGraphData]);

    useEffect(() => {
        if (filterDepGraphHook?.filteredGraphData !== null && filterDepGraphHook?.filteredGraphData !== undefined) {
               console.log("filtered dep graph data - ", filterDepGraphHook.filteredGraphData);
        setRenderedDependentStatusData(filterDepGraphHook.filteredGraphData[0]);
        setDepCardData(filterDepGraphHook.filteredGraphCardData[0])
        }
     
    }, [filterDepGraphHook.filteredGraphData]);

    useEffect(() => {
        console.log("filtered ineligible graph data - ", filterIneligibleGraphHook.filteredGraphData);
        console.log("filtered ineligible card graph data - ", filterIneligibleGraphHook.filteredGraphCardData);
        //console.log("filtered ineligible card graph data temp - ", filterIneligibleGraphHook.tempArr);
      
        if (filterIneligibleGraphHook?.filteredGraphData[0]?.total > 0) {
            console.log("filtered ineligible card data --- ", filterIneligibleGraphHook.filteredGraphCardData[0])
            setRenderedFinalIneligibleList(filterIneligibleGraphHook.filteredGraphCardData);
            setIneligibleCardData(filterIneligibleGraphHook.filteredGraphCardData);

            setRenderedSecIneligibleList([])
            setRenderedFirstIneligibleList([])
            if (filterIneligibleGraphHook.filteredGraphCardData.length > 8) {
                setExtendIneligibleGraphContainer(true);
                for (let i = 0; i < filterIneligibleGraphHook.filteredGraphCardData.length; i++) {
                    if (i <= 7) {
                        setRenderedFirstIneligibleList(renderedFirstIneligibleList => [...renderedFirstIneligibleList, filterIneligibleGraphHook.filteredGraphCardData[i]])
                    }

                    if (i > 7) {
                        setRenderedSecIneligibleList(renderedSecIneligibleList => [...renderedSecIneligibleList, filterIneligibleGraphHook.filteredGraphCardData[i]])
                    }

                }
            }

            if (filterIneligibleGraphHook.filteredGraphCardData.length === 0) {
                setHideIneligibleChart(true);
            }


        } else {
            setRenderedFinalIneligibleList([]);
            setIneligibleCardData([]);
            //filterIneligibleGraphHook.setTempArr([]); 
        }
        //filterIneligibleGraphHook.setTempArr([]); //reset temp arr to avoid duplicates when calculating ineligible graph hook
        
     
        //setShowIneligibleChart(true);
       

        setRenderedIneligibleStatusData(filterIneligibleGraphHook.filteredGraphData[0]);
        //setRenderedFinalIneligibleList(filterIneligibleGraphHook.filteredGraphCardData);
        
    }, [filterIneligibleGraphHook.filteredGraphData]);

    
    useEffect(() => {
        console.log("filtered incomplete graph data - ", filterIncompleteGraphHook.filteredGraphData);
        setRenderedIncompleteStatusData(filterIncompleteGraphHook.filteredGraphData[0]);
        setIncompleteCardData(filterIncompleteGraphHook.filteredGraphCardData[0]);
    }, [filterIncompleteGraphHook.filteredGraphData]);

    //useEffect(() => {
    //    console.log("filtered member graph data - ", filterMemberGraphHook.filteredGraphData);
    //    setRenderedMemberStatusData(filterMemberGraphHook.filteredGraphData[0]);
    //    setMemberCardData(filterMemberGraphHook.filteredGraphData[0]);
    //}, [filterMemberGraphHook.filteredGraphData]);

  
    
    const { permissions, setPermissions } = useContext(PermissionsContext);
    const { roles, setRoles } = useContext(RolesContext);
    const { accounts, instance } = useMsal();
    const { id, auditName } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    //const [isTwoLists, setIsTwoLists] = useState(false);
    //const [firstIneligibleList, setFirstIneligibleList] = useState([]);
    //const [secIneligibleList, setSecIneligibleList] = useState([]);
    //const [finalIneligibleList, setFinalIneligibleLst] = useState([]);
   // const [hideIneligibleChart, setHideIneligibleChart] = useState(false);
    const [validRoute, setValidRoute] = useState(null);
    //const [showIneligibleChart, setShowIneligibleChart] = useState(true);
    const [showIncompleteChart, setShowIncompleteChart] = useState(true);
    const [popAlertToast, setPopAlertToast] = useState(false);
    const fetchDataRef = useRef(false);
    const [promiseIsLoading, setPromiseIsLoading] = useState(true);
    const [displayIneligibleChart, setDisplayIneligibleChart] = useState();
    //const [familyReport, setFamilyReport] = useState([]);
    //const [dependentReport, setDependentReport] = useState([]);
    //const [alertReport, setAlertReport] = useState([]);
    //const [ineligibleReport, setIneligibleReport] = useState([]);
    //const [incompleteReport, setIncompleteReport] = useState([]);


    useEffect(() => { //called when route params are set 

        if (id !== undefined) {
            if (id !== sidebarActiveClientID && id !== projectId) {
                setIsLoading(false);
                console.log("hit promise is loading in id/auditname, no matching projectId - ", promiseIsLoading);
                setPromiseIsLoading(true);
            }
        } else if (id === undefined) { //if id is undefined and user is not rerouted (due to permissions handling) this means user is most likely a client with no active audits.
            setIsLoading(true);
            console.log("hit promise is loading in id/auditname, undefined id - ", promiseIsLoading);
            setPromiseIsLoading(true);
            setSidebarActiveClientID(' ');
            setSidebarTitle(' ');
            toast.info("There are no active audits", {
                position: "bottom-center",
                theme: "colored",
                autoClose: false,
                closeOnClick: true,
                toastId: "noActiveAudits"
            });
        }

     

    }, [id, auditName]);


    useEffect(() => {
  
        if (isLoading === false && id !== undefined) {
            if (id !== projectId) {
                setProjectId(id);
                setSidebarActiveClientID(`${id}`)
                setSidebarTitle(`${auditName}`)
                //console.log("setSideBar call - ", auditName)
                setFamilyReport([]);
                setDependentReport([]);
                setAlertReport([]);
                setIneligibleReport([]);
                //setMemberReport([]);
                setResponseRateData();
                setEmployeeStatusData();
                setDependentStatusData();
                setIneligibleStatusData();
                setIncompleteStatusData();
                //setMemberStatusData();
                setIncompleteReport([]);
                setPopAlertToast(false)
                setIsLoading(true);

                //reset unfiltered data
                setUnfilteredFamilyReport([]);
                setUnfilteredDependentReport([]);
                setUnfilteredAlertReport([]);
                setUnfilteredIneligibleReport([]);
                setUnfilteredIncompleteReport([]);
                //setUnfilteredMemberReport([]);
                setUnfilteredIncompleteStatusData();
                setUnfilteredIneligibleStatusData();
                //setShowIncompleteChart(false);
                //setShowIneligibleChart(false);

                

                //reset rendered data since project data will need to be refetched
                setRenderedFamilyReport([]);
                setRenderedDependentReport([]);
                setRenderedAlertReport([]);
                setRenderedIneligibleReport([]);
                setRenderedIncompleteReport([]);
                //setRenderedMemberReport([]);
                setRenderedAlertNoteCount([]);
                setRenderedIncompleteStatusData();
                setRenderedIneligibleStatusData();
              

                console.log("hit promise is loading isLoading, no matching projectId - ", promiseIsLoading);
                setPromiseIsLoading(true);
                //setIsReportRendering(true);
            }

            setSidebarActiveClientID(`${id}`)
            setSidebarTitle(`${auditName}`)
            console.log("setSideBar call - ", auditName)
            setPopAlertToast(false)
            if (id === projectId) {
                //console.log("is loading call setPromiseIsLoading", promiseIsLoading);
                console.log("hit promise is loading isLoading, matching projectId - ", promiseIsLoading);
                console.log("is loading familyCard ", familyCardData);


              
                console.log("active audit end date on page change - ", activeClientAuditEndDates);
                //handleUpdateActiveClientAuditEndDates([{
                //    auditEndDate: "12/12/1800"
                //}]);
                console.log("return to current project -RenderedFam - ", renderedFamilyReport);
                console.log("return to current project -RenderedDep - ", renderedDependentReport);
                console.log("return to current project -RenderedInel - ", renderedIneligibleReport);
                console.log("return to current project -RenderedIncompl - ", renderedIncompleteReport);
                console.log("return to current project -RenderedMember - ", renderedMemberReport);
                console.log("return to current project -RenderedAlert - ", renderedAlertReport);
                filterFamGraphHook.handleFamilyGraphFiltering(renderedFamilyReport);
                filterDepGraphHook.handleDependentGraphFiltering(renderedDependentReport);
                filterIneligibleGraphHook.handleIneligibleGraphFiltering(renderedIneligibleReport);
                filterIncompleteGraphHook.handleIncompleteGraphFiltering(renderedIncompleteReport);
                //filterMemberGraphHook.handleMemberGraphFiltering(renderedMemberReport);
                //setCallFilter(!callFilter);
                setPromiseIsLoading(false);
            }

        } else if (id === "undefined") {
            
            console.log("hit promise is loading isLoading, undefined - ", promiseIsLoading);
            setPromiseIsLoading(true);
        }

  

    }, [isLoading]);

    const { newRoutes, setRoutes } = useContext(RoutesContext);

    const [pageRoutes, setPageRoutes] = useState([
        {
            id: 0,
            label: "Home",
            path: "report",
            icon: <HomeIcon />,
            isActive: false,
            style: '',
            isNested: false
        },
        {
            id: 1,
            label: "Project",
            path: `report/${String(id)}/${auditName}`,
            icon: <BarChartIcon />,
            isActive: true,
            style: 'active-navLink border-bottom__thick-blue',
            isNested: true
        },
        {
            id: 2,
            label: "Users",
            path: `report/${String(id)}/${auditName}/users`,
            icon: <GroupIcon />,
            isActive: false,
            style: '',
            isNested: true
        },
        {
            id: 4,
            label: "Processing",
            path: "processing",
            icon: <AssignmentTurnedInIcon />,
            isActive: false,
            style: '',
            isNested: false
        }
    ]);

    //const [alertNoteCount, setAlertNoteCount] = useState(0);
    const [reportBadge, setReportBadge] = useState(true);

    const [familyStatusTableDataTemp, setFamilyStatusTableDataTemp] = useState([]);

    //useEffect(() => {

    //    if ((roles === "SERVICES")) {
    //        navigate('/processing');
    //    }
    //    console.log("permissions - ", permissions);
    //    console.log("valid Route --- ", validRoute)
    //    if ((roles === "CLIENT") && (validRoute === false || validRoute === null)) {

    //        navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
    //        //setIsLoading(false);

    //    }


    //}, [validRoute, setValidRoute, roles]);

    useEffect(() => {

        if ((roles === "SERVICES")) {
            navigate('/processing');
        }
        console.log("permissions - ", permissions);
        console.log("valid Route --- ", validRoute)
        if ((roles === "CLIENT") && (validRoute === false)) {

            navigate(`/report/${String(permissions[0]?.auditId)}/${permissions[0]?.auditName}`, { replace: true });
            //setIsLoading(false);

        }


    }, [setValidRoute, validRoute, roles]);



    useEffect(() => {
        let checkPerms = false;
            permissions?.map((audit) => {
                if (audit?.auditId === parseInt(id)) {
                    setValidRoute(true)
                    setCount(count + 1);
                    checkPerms = true;
                }

            });
            console.log("count -- ", count)
            console.log("checkPerms -- ", checkPerms);
            console.log("permissions -- ", permissions)
            //if ((validRoute === null || validRoute === false) && (count === permissions.length)) {
            //    setValidRoute(false)
            //}

        if (checkPerms === false) {
            setValidRoute(false);
        }


        

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length <= 1)) {
            setRoutes([
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                },
                //{
                //    id: 3,
                //    label: "Search",
                //    path: `report/${String(id)}/${auditName}/search`,
                //    icon: <SearchIcon />,
                //    isActive: false,
                //    style: '',
                //    isNested: false
                //},
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "CLIENT") && (permissions.length > 1)) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                //{
                //    id: 3,
                //    label: "Search",
                //    path: `report/${String(id)}/${auditName}/search`,
                //    icon: <SearchIcon />,
                //    isActive: false,
                //    style: '',
                //    isNested: true
                //},
            ]);
        }

        if (({ pageRoutes } != { newRoutes }) && (roles === "PROJMGR")) {
            setRoutes([
                {
                    id: 0,
                    label: "Home",
                    path: "report",
                    icon: <HomeIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 1,
                    label: "Project",
                    path: `report/${String(id)}/${auditName}`,
                    icon: <BarChartIcon />,
                    isActive: true,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: true
                },
                {
                    id: 2,
                    label: "Users",
                    path: `report/${String(id)}/${auditName}/users`,
                    icon: <GroupIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 3,
                    label: "Search",
                    path: `report/${String(id)}/${auditName}/search`,
                    icon: <SearchIcon />,
                    isActive: false,
                    style: '',
                    isNested: true
                },
                {
                    id: 4,
                    label: "Processing",
                    path: "processing",
                    icon: <AssignmentTurnedInIcon />,
                    isActive: false,
                    style: '',
                    isNested: false
                },
                {
                    id: 5,
                    label: "Search All",
                    path: "globalsearch",
                    icon: <TravelExploreIcon />,
                    isActive: false,
                    style: 'active-navLink border-bottom__thick-blue',
                    isNested: false
                }
            ])

        }


    }, [id, auditName, roles]);

    const [count, setCount] = useState(0);



    useEffect(() => {

        if (projectId !== id || id === "undefined") {
            setIneligibleStatusData()
            setFirstIneligibleList([])
            setFinalIneligibleList([])
            setSecIneligibleList([])
            setExtendIneligibleGraphContainer(false);
        }

        permissions?.map((audit) => {
            if (audit?.auditId === parseInt(id)) {
                setValidRoute(true)
                setCount(count + 1);
            }
            console.log(" audit in permissions project page - ", audit);

        });
        console.log("count -- ", count)
        console.log("permissions -- ", permissions)
        if ((validRoute === null || validRoute === false) && (count === permissions.length)) {
            setValidRoute(false)
        }
        console.log("current id --- ", id);
     



    }, []);
    

    //const [extendIneligibleGraphContainer, setExtendIneligibleGraphContainer] = useState(false);

    const  failedFetchError = (status) => {
       
        toast.error(String(status) + " error: Please refresh and try again.", {
            position: "bottom-center",
            theme: "colored",
            autoClose: false,
            closeOnClick: true,
            toastId: "failedToFetch"
        });


    }

    

    const fetchGraphDetails = useFetchGraphDetails();

    useEffect(() => {
        console.log("handleFetchAll useEffect ", isLoading, " id - ", id, ", projectId - ", projectId, " sideActiveClientId - ", sidebarActiveClientID);
        if (isLoading === false && id !== undefined && id !== null && projectId !== id) {
            fetchGraphDetails.handleFetchAll(id);
            console.log("handleFetchAll condition ", isLoading, " ", id);
        }
      

    }, [sidebarActiveClientID, accounts, id, instance, isLoading]);

    useEffect(() => {
        if (fetchGraphDetails.familyData?.length > 0){
            //setFamilyReport(fetchGraphDetails.familyData); //set family report
            //setRenderedFamilyReport(fetchGraphDetails.familyData);
            //setResponseRateData(fetchGraphDetails.familyData); //setResponseRate
            console.log("fetch graph details - family data -- ", fetchGraphDetails.familyData);
            filterFamGraphHook.handleFamilyGraphFiltering(fetchGraphDetails.familyData);
            //filterFamGraphHook.handleFamilyGraphFiltering(fetchGraphDetails.familyData);
        }
      

    }, [fetchGraphDetails.familyData]); 

    useEffect(() => {

        console.log("fetchGraphDetails called ", fetchGraphDetails.ineligibleData);
        if (fetchGraphDetails.ineligibleData !== undefined && fetchGraphDetails.ineligibleData !== null && fetchGraphDetails.ineligibleData?.length > 0) {
            console.log("fetch graph details - ineligible data -- ", fetchGraphDetails.ineligibleData);
            filterIneligibleGraphHook.handleIneligibleGraphFiltering(fetchGraphDetails.ineligibleData);
        }

    }, [fetchGraphDetails.ineligibleData]);

    useEffect(() => {

        //if (fetchGraphDetails.auditEndDates !== undefined && fetchGraphDetails.auditEndDates !== null && fetchGraphDetails?.auditEndDates?.length > 0) {
        //    /* Audit End Dates */
        //    handleUpdateAvailableClientAuditEndDates(fetchGraphDetails.auditEndDates);

        //}

    }, [fetchGraphDetails.auditEndDates])


    useEffect(() => {
        console.log("fetch graph details promise is loading called outside if ", fetchGraphDetails?.promiseIsLoading);
        if (fetchGraphDetails.promiseIsLoading !== null && fetchGraphDetails.promiseIsLoading !== undefined && fetchGraphDetails.promiseIsLoading !== true) {
            console.log("fetch graph details promise is loading called inside if ", fetchGraphDetails?.promiseIsLoading);
            setPromiseIsLoading(false);

        }
        

    }, [fetchGraphDetails.promiseIsLoading])

    useEffect(() => {

        if (fetchGraphDetails.throwFetchError !== null && fetchGraphDetails.throwFetchError !== undefined && fetchGraphDetails.fetchStatus !== null && fetchGraphDetails.fetchStatus !== undefined) {
            failedFetchError(fetchGraphDetails.fetchStatus);
        }

    }, [fetchGraphDetails.throwFetchError]);

    useEffect(() => {
        if (toast.isActive("newAlertsNotification")) {
            toast.update("newAlertsNotification", {
                type: toast.TYPE.ERROR,
                theme: "colored",
                autoClose: false,
                closeOnClick: true,
                style: { background: '#d32f2f' },
                
            })
        }else if ((popAlertToast === true) && (!toast.isActive("newAlertsNotification"))){
            toast.error("Alerts are available to export", {
                position: "bottom-center",
                theme: "colored",
                autoClose: false,
                closeOnClick: true,
                style: { background: '#d32f2f' },
                toastId: "newAlertsNotification"
               
            });
        }

    }, [popAlertToast]);

  

    //useEffect(() => {
    //    //split ineligible status list before displaying if there are more than 8 statuses
    //    setRenderedSecIneligibleList([])
    //    setRenderedFirstIneligibleList([])
    //    if (renderedFinalIneligibleList.length > 8) {
    //        setExtendIneligibleGraphContainer(true);
    //        for (let i = 0; i < renderedFinalIneligibleList.length; i++) {
    //            if (i <= 7) {
    //                setRenderedFirstIneligibleList(renderedFirstIneligibleList => [...renderedFirstIneligibleList, renderedFinalIneligibleList[i]])
    //            }

    //            if (i > 7) {
    //                setRenderedSecIneligibleList(renderedSecIneligibleList => [...renderedSecIneligibleList, renderedFinalIneligibleList[i]])
    //            }

    //        }
    //    }

    //    if (renderedFinalIneligibleList.length === 0) {
    //        setHideIneligibleChart(true);
    //    }


    //}, [setRenderedFinalIneligibleList, displayIneligibleChart]);


    //useEffect(() => {

    //    console.log("family report initial -- ", familyReport);

    //}, []);


    /* EMPLOYEE STATUS GRAPH DATA*/

    /* let ESGrandTotal = employeeStatusData?.employeeStatusGrandTotal;*/
    let ESCompleteTotal = renderedEmployeeStatusData?.employeeStatusCompleteTotal;
    let ESIncompleteTotal = renderedEmployeeStatusData?.employeeStatusIncompleteTotal;
    let ESNoResponseTotal = renderedEmployeeStatusData?.employeeStatusNoResponseTotal;
    let ESCompletePercent = Number(renderedEmployeeStatusData?.employeeStatusCompletePercent * 100).toFixed(2);
    let ESIncompletePercent = Number(renderedEmployeeStatusData?.employeeStatusIncompletePercent * 100).toFixed(2);
    let ESNoResponsePercent = Number(renderedEmployeeStatusData?.employeeStatusNoResponsePercent * 100).toFixed(2);

    /* DEPENDENT STATUS GRAPH DATA*/

    let DSGrandTotal = renderedDependentStatusData?.dependentStatusGrandTotal;
    let DSPassedTotal = renderedDependentStatusData?.dependentStatusPassedTotal;
    let DSSuspendTotal = renderedDependentStatusData?.dependentStatusSuspendTotal;
    let DSIneligibleTotal = renderedDependentStatusData?.dependentStatusIneligibleTotal;
    let DSReviewTotal = renderedDependentStatusData?.dependentStatusReviewTotal;
    let DSIncompleteTotal = renderedDependentStatusData?.dependentStatusIncompleteTotal;
    let DSNoResponseTotal = renderedDependentStatusData?.dependentStatusNoResponseTotal;


    let DSPassedPercent = Number(renderedDependentStatusData?.dependentStatusPassedPercent * 100).toFixed(2);
    let DsSuspendPercent = Number(renderedDependentStatusData?.dependentStatusSuspendPercent * 100).toFixed(2);
    let DSIneligiblePercent = Number(renderedDependentStatusData?.dependentStatusIneligiblePercent * 100).toFixed(2);
    let DSReviewPercent = Number(renderedDependentStatusData?.dependentStatusReviewPercent * 100).toFixed(2);
    let DSIncompletePercent = Number(renderedDependentStatusData?.dependentStatusIncompletePercent * 100).toFixed(2);
    let DSNoResponsePercent = Number(renderedDependentStatusData?.dependentStatusNoResponsePercent * 100).toFixed(2);

    /* Incomplete Status Graph data */
    let ISInvalidDocReason = renderedIncompleteStatusData?.invalidDocumentReason;
    let ISInvalidDocPercent = Number(renderedIncompleteStatusData?.invalidDocPercent * 100).toFixed(2);
    let ISMissingDocReason = renderedIncompleteStatusData?.missingDocReason;
    let ISMissingDocPercent = Number(renderedIncompleteStatusData?.missingDocPercent * 100).toFixed(2);
    let ISDocNotLegibleReason = renderedIncompleteStatusData?.docNotLegibleReason;
    let ISDocNotLegiblePercent = Number(renderedIncompleteStatusData?.docNotLegiblePercent * 100).toFixed(2);
    let ISGrandTotal = renderedIncompleteStatusData?.total;

    /* MEMBER/EMPLOYEE STATUS GRAPH DATA*/

    let MSGrandTotal = renderedMemberStatusData?.memberStatusGrandTotal;
    let MSPassedTotal = renderedMemberStatusData?.memberStatusPassedTotal;
    let MSSuspendTotal = renderedMemberStatusData?.memberStatusSuspendTotal;
    let MSIneligibleTotal = renderedMemberStatusData?.memberStatusIneligibleTotal;
    let MSReviewTotal = renderedMemberStatusData?.memberStatusReviewTotal;

    let MSIncompleteTotal = renderedMemberStatusData?.memberStatusIncompleteTotal;
    let MSNoResponseTotal = renderedMemberStatusData?.memberStatusNoResponseTotal;


    let MSPassedPercent = Number(renderedMemberStatusData?.memberStatusPassedPercent * 100).toFixed(2);

    let MSSuspendPercent = Number(renderedMemberStatusData?.memberStatusSuspendPercent * 100).toFixed(2);
    let MSIneligiblePercent = Number(renderedMemberStatusData?.memberStatusIneligiblePercent * 100).toFixed(2);
    let MSReviewPercent = Number(renderedMemberStatusData?.memberStatusReviewPercent * 100).toFixed(2);
    let MSIncompletePercent = Number(renderedMemberStatusData?.memberStatusIncompletePercent * 100).toFixed(2);
    let MSNoResponsePercent = Number(renderedMemberStatusData?.memberStatusNoResponsePercent * 100).toFixed(2);

    let RRGrandTotal = renderedResponseRateData?.responseRateGrandTotal;
    let RRNoResponsePercent = Number(renderedResponseRateData?.responseRateNoResponsePercent * 100).toFixed(2);
    let RRNoResponseTotal = renderedResponseRateData?.responseRateNoResponseTotal;
    let RRRespondedPercent = Number(renderedResponseRateData?.responseRateRespondedPercent * 100).toFixed(2);
    let RRRespondedTotal = renderedResponseRateData?.responseRateRespondedTotal;

 


    const [reports, setReports] = useState([]);
    const initStatusTally = [{ id: 'No Response', value: 0, color: "hsl(85, 70%, 50%)" }, { id: 'Incomplete', value: 4000, color: "hsl(287, 70%, 50%)" }, { id: 'Complete', value: 100, color: "hsl(248, 70%, 50%)" }, { id: 'Ineligible', value: 500 }, { id: 'Suspend', value: 0, color: "hsl(142, 70%, 50%)" }]
    const [statusTally, setStatusTally] = useState(initStatusTally)
    const initEmailTally = [{ id: 'Yes', value: 928, color: "hsl(248, 70%, 50%)" }, { id: 'No', value: 207, color: "hsl(85, 70%, 50%)" }]
    const [emailTally, setEmailTally] = useState(initEmailTally)
    const initTotalTally = [{ type: "Employee", value: 0 }, { type: "Dependent", value: 0 }, { type: "Combined", value: 0 }]
    const [totalTally, setTotalTally] = useState(initTotalTally)
    const calendarDates = [{ value: 22, day: "2023-01-17" }, { value: 49, day: "2023-01-23" }, { value: 105, day: "2023-02-13" }, { value: 53, day: "2023-02-15" }, { value: 2, day: "2023-02-17" }, { value: 86, day: "2023-02-21" }];


 

    const [dependentReportData, setDependentReportData] = useState([]);
    
    const debouncefetchCSV = debounce(async () => {
        function fetchCSVFormattedDataAndPackageForDownload() {
            

            //export to CSV
            const options = {
                filename: `DependentStatusReport-${auditName}`,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };

            

            const options2 = {
                filename: `FamilyStatusReport-${auditName}`,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };



            const options3 = {
                filename: `AlertReport-${auditName}`,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };

            const options4 = {
                filename: `IneligibleReport-${auditName}`,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };


            const options5 = {
                filename: `IncompleteReport-${auditName}`,
                fieldSeparator: ',',
                quoteStrings: '"',
                decimalSeparator: '.',
                showLabels: true,
                showTitle: false,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            };

            //const options6 = {
            //    filename: `MemberStatusReport-${auditName}`,
            //    fieldSeparator: ',',
            //    quoteStrings: '"',
            //    decimalSeparator: '.',
            //    showLabels: true,
            //    showTitle: false,
            //    useTextFile: false,
            //    useBom: true,
            //    useKeysAsHeaders: true,
            //    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
            //};

                //const csvExporter6 = new ExportToCsv(options6);
                const csvExporter5 = new ExportToCsv(options5);
                const csvExporter4 = new ExportToCsv(options4);
                const csvExporter3 = new ExportToCsv(options3);
                const csvExporter2 = new ExportToCsv(options2);
                const csvExporter = new ExportToCsv(options);
              
                console.log("rend family - ", renderedFamilyReport);
                console.log("rend dep - ", renderedDependentReport);
                console.log("rend alert - ", renderedAlertReport);
                console.log("rend ineg - ", renderedIneligibleReport);
                console.log("rend incomplete - ", renderedIncompleteReport);
                console.log("rend member - ", renderedMemberReport);
          
                //if (renderedMemberReport !== null && renderedMemberReport !== undefined && renderedMemberReport?.length > 0){
                //    csvExporter6.generateCsv(renderedMemberReport); //member
                //}
                if (renderedIncompleteReport !== null && renderedIncompleteReport !== undefined && renderedIncompleteReport?.length > 0) {
                    csvExporter5.generateCsv(renderedIncompleteReport); //incomplete
                }
                if (renderedIneligibleReport !== null && renderedIneligibleReport !== undefined && renderedIneligibleReport?.length > 0) {
                    csvExporter4.generateCsv(renderedIneligibleReport); //ineligible
                }
                if (renderedDependentReport !== null && renderedDependentReport !== undefined && renderedDependentReport?.length > 0) {
                    csvExporter.generateCsv(renderedDependentReport); //dependent
                }
                if (renderedAlertReport !== null && renderedAlertReport !== undefined && renderedAlertReport?.length > 0) {
                    csvExporter3.generateCsv(renderedAlertReport); //alert
                }
                if (renderedFamilyReport !== null && renderedFamilyReport !== undefined && renderedFamilyReport?.length > 0) {
                    csvExporter2.generateCsv(renderedFamilyReport); //family
                }
            

        }
        fetchCSVFormattedDataAndPackageForDownload();
    }, 10000, { 'leading': true, 'trailing': false });

    return (
        <>
            <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', mr: 0, display: { xs: 'none', md: 'block', xl: 'block' } }} >

                {/*  DESKTOP PAGE TITLES/HEADINGS */}
                <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>
                    <div className="row-no-left-margin" style={{ width: "82.5VW" }}>
                        <div className="row-left">

                            {promiseIsLoading === false ? (<>
                                <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>Dashboard</Typography>
                                {availableClientAuditEndDates?.length !== 0 ? (<><AuditEndDateMenu /></>) : (<></>)}
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={126} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                        <div className="row-right">

                            {promiseIsLoading === false ? (<>

                                <Tooltip title="Export Graph Data as a CSV">
                                    {/* <Badge color="error" badgeContent={alertNoteCount} invisible={reportBadge}>*/}
                                    <Button

                                        variant="contained"
                                        disableElevation sx={{
                                            width: '4VW', height: '3VW',
                                            bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', boxShadow: 0,
                                            ':hover': {
                                                bgcolor: '#dddddd', // theme.palette.primary.main
                                                color: '#ffffff',
                                                boxShadow: 0,
                                            },
                                        }} onClick={() => { debouncefetchCSV(); }}
                                        style={{ textTransform: 'none' }}
                                        disabled={isReportRendering}

                                    >
                                        <Typography fontWeight="600" fontSize="18px" >Export</Typography>
                                    </Button>
                                    {/*  </Badge>*/}
                                </Tooltip>
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={72} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                    </div>
                </Container >


                {/* Project Cards - DESKTOP  */}
                <Container sx={{ ml: 0, }}>
                    <Grid container spacing={3} sx={{ mt: 0, mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>


                    </Grid>
                </Container>

                <Container sx={{ ml: 0 }}>
                    <Grid container spacing={3} sx={{ mt: 0.5, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                        <Grid item xs="auto" >

                          
                            {employeeStatusData !== undefined  ? (
                                familyCardData?.length > 0 && (familyCardData[3]?.RRGrandTotal !== 0 || familyCardData[3]?.RRNoResponseTotal !== 0 || familyCardData[3]?.RRRespondedTotal !== 0) ? (
                                <GraphCard
                                    graphName={"Response Rate"}
                                    desiredCardWidth={340}
                                    desiredCardHeight={400}
                                    graphLink={null}
                                    graphData={null}
                                    graphComponent={




                                        /*
                                        <PieGraph
                                            graphValueA={RRRespondedTotal}
                                            graphValueB={RRNoResponseTotal}
                                            graphValueC={0}
                                            graphLabelValueA={"Responded"}
                                            graphLabelValueB={"No Response"}
                                            metricSubTitle={'Response Rate'}
                                            alternateCenterMetricPercent={RRRespondedPercent}
                                            graphTitle={`Response Rate Graph - ${RRRespondedPercent} Total Percent Responded`}
                                        />
                                        */
                                        <PieGraph
                                            graphValueA={familyCardData[3]?.RRRespondedTotal}
                                            graphValueB={familyCardData[3]?.RRNoResponseTotal}
                                            graphValueC={0}
                                            graphLabelValueA={"Responded"}
                                            graphLabelValueB={"No Response"}
                                            metricSubTitle={'Response Rate'}
                                            alternateCenterMetricPercent={familyCardData[3]?.RRRespondedPercent}
                                            graphTitle={`Response Rate Graph - ${familyCardData[3]?.RRRespondedPercent} Total Percent Responded`}
                                        />




                                    }
                                    graphLegendComponent={
                                        /*responseRateData != undefined ? (*/
                                        familyCardData?.length > 0 && familyCardData !== undefined && (familyCardData[3]?.RRGrandTotal !== 0 || familyCardData[3]?.RRNoResponseTotal !== 0 || familyCardData[3]?.RRRespondedTotal !== 0) ? ( 
                                            <GraphLegendCard
                                                //graphLegendData={
                                                //    [
                                                //        {
                                                //            metricTitle: "Responded",
                                                //            metricValue: RRRespondedTotal,
                                                //            metricPercentage: RRRespondedPercent,
                                                //        },
                                                //        {
                                                //            metricTitle: "No Response",
                                                //            metricValue: RRNoResponseTotal,
                                                //            metricPercentage: RRNoResponsePercent,
                                                //        },
                                                //    ]
                                                //}
                                                graphLegendData={familyCardData[1]}
                                            />) : (<></>)
                                    }
                                    />
                                ) : (<></>)
                                ) : (<>
                                    <GraphCard
                                        graphName={""}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphLink={null}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={

                                            <></>
                                        }
                                    />
                                </>)}

                        </Grid>

                        {employeeStatusData != undefined ? (

                            renderedFamilyReport.length > 0 ? (<>
                                <Grid item xs="auto" >
                                    <GraphCard
                                        graphTableData={familyReport}
                                        graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                        graphName={"Family Status"}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphData={[
                                            {
                                                metricTitle: "Complete",
                                                metricValue: ESCompleteTotal,
                                                metricPercentage: ESCompletePercent,
                                            },
                                            {
                                                metricTitle: "No Response",
                                                metricValue: ESNoResponseTotal,
                                                metricPercentage: ESNoResponsePercent,
                                            },
                                            {
                                                metricTitle: "Incomplete",
                                                metricValue: ESIncompleteTotal,
                                                metricPercentage: ESIncompletePercent,
                                            },
                                        ]}
                                        graphComponent={

                                            <PieGraph
                                                graphValueA={ESCompleteTotal}
                                                graphValueB={ESNoResponseTotal}
                                                graphValueC={ESIncompleteTotal}
                                                graphLabelValueA={"Complete"}
                                                graphLabelValueB={"No Response"}
                                                graphLabelValueC={"Incomplete"}
                                                metricSubTitle={'Families'}
                                                graphTitle={`Employee Status Graph - ${ESCompleteTotal} Complete employees out of ${ESCompleteTotal + ESNoResponseTotal + ESIncompleteTotal} Total Employees`}
                                            />
                                        }
                                        graphLegendComponent={

                                            <GraphLegendCard
                                                graphLegendData={
                                                    [
                                                        {
                                                            metricTitle: "Complete",
                                                            metricValue: ESCompleteTotal,
                                                            metricPercentage: ESCompletePercent,
                                                        },
                                                        {
                                                            metricTitle: "No Response",
                                                            metricValue: ESNoResponseTotal,
                                                            metricPercentage: ESNoResponsePercent,
                                                        },
                                                        {
                                                            metricTitle: "Incomplete",
                                                            metricValue: ESIncompleteTotal,
                                                            metricPercentage: ESIncompletePercent,
                                                        },
                                                    ]
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                            </>) : (<></>)

                        ) : (<>
                            <Grid item xs="auto" >
                                <GraphCard
                                    graphLink={null}
                                    graphName={""}
                                    desiredCardWidth={340}
                                    desiredCardHeight={400}
                                    graphComponent={

                                        <div className="row-no-left-margin">
                                            <div className="center">
                                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                    <Skeleton height={360} width={308} />
                                                </SkeletonTheme>
                                            </div>
                                        </div>

                                    }
                                    graphLegendComponent={
                                        <></>
                                    }
                                />
                            </Grid>
                        </>)}




                        {dependentStatusData != undefined ? (
                            renderedDependentReport.length !== 0 ? (<>
                                <Grid item xs="auto" >
                                    {/* ACTUAL COMPONENT  */}
                                    <GraphCard
                                        graphName={"Dependent Status"}
                                        graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                        graphTableData={dependentReport}
                                        graphData={[
                                            {
                                                metricTitle: "No Response",
                                                metricValue: DSNoResponseTotal,
                                                metricPercentage: DSNoResponsePercent,
                                            },
                                            {
                                                metricTitle: "Incomplete",
                                                metricValue: DSIncompleteTotal,
                                                metricPercentage: DSIncompletePercent,
                                            },
                                            {
                                                metricTitle: "Review",
                                                metricValue: DSReviewTotal,
                                                metricPercentage: DSReviewPercent,
                                            },
                                            {
                                                metricTitle: "Passed",
                                                metricValue: DSPassedTotal,
                                                metricPercentage: DSPassedPercent,
                                            },
                                            {
                                                metricTitle: "Suspend",
                                                metricValue: DSSuspendTotal,
                                                metricPercentage: DsSuspendPercent,
                                            },
                                            {
                                                metricTitle: "Ineligible",
                                                metricValue: DSIneligibleTotal,
                                                metricPercentage: DSIneligiblePercent,
                                            },

                                        ]}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphLegendComponent={

                                            <PercentageBarChart
                                                percentageBarChartData={
                                                    [
                                                        {
                                                            metricTitle: "No Response",
                                                            metricValue: DSNoResponseTotal,
                                                            metricPercentage: DSNoResponsePercent,
                                                        },
                                                        {
                                                            metricTitle: "Incomplete",
                                                            metricValue: DSIncompleteTotal,
                                                            metricPercentage: DSIncompletePercent,
                                                        },
                                                        {
                                                            metricTitle: "Review",
                                                            metricValue: DSReviewTotal,
                                                            metricPercentage: DSReviewPercent,
                                                        },
                                                        {
                                                            metricTitle: "Passed",
                                                            metricValue: DSPassedTotal,
                                                            metricPercentage: DSPassedPercent,
                                                        },
                                                        {
                                                            metricTitle: "Suspend",
                                                            metricValue: DSSuspendTotal,
                                                            metricPercentage: DsSuspendPercent,
                                                        },
                                                        {
                                                            metricTitle: "Ineligible",
                                                            metricValue: DSIneligibleTotal,
                                                            metricPercentage: DSIneligiblePercent,
                                                        },

                                                    ]
                                                }
                                            />}
                                    />
                                </Grid>
                            </>) : (<>

                            </>)
                        ) : (<>
                            <Grid item xs="auto" >
                                <GraphCard
                                    graphLink={null}
                                    graphName={""}
                                    desiredCardWidth={340}
                                    desiredCardHeight={400}
                                    graphComponent={

                                        <div className="row-no-left-margin">
                                            <div className="center">
                                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                    <Skeleton height={360} width={308} />
                                                </SkeletonTheme>
                                            </div>
                                        </div>

                                    }
                                    graphLegendComponent={
                                        <></>
                                    }
                                />
                            </Grid>
                        </>)}


                        <>
                            {/*{showIneligibleChart ? (
                                <>
                            */}
                                    {renderedFinalIneligibleList !== undefined ? (<>
                                        {extendIneligibleGraphContainer ? (
                                            renderedFinalIneligibleList?.length > 0 &&  renderedFinalIneligibleList !== null && renderedIneligibleReport?.length > 0 ? (<>
                                                <Grid item xs={7.5}>
                                                    <IneligibleExtendedGraphCard
                                                        graphTableData={ineligibleReport}
                                                        graphName={"Ineligible Reason"}
                                                        graphLegendStyle={"Ineligible Reason XY"}
                                                        graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                                        graphData={renderedFinalIneligibleList}
                                                        desiredCardWidth={708}
                                                        desiredCardHeight={400}
                                                        graphLegendComponent={
                                                            <Grid container columnSpacing={{ xs: 2 }} justifyContent="center">
                                                                <Grid item sx={2}>
                                                                    <IneligibleReasonBarChart
                                                                        /*percentageBarChartData={*/
                                                                        /*    firstIneligibleList*/
                                                                        /*}*/
                                                                        percentageBarChartData={renderedFirstIneligibleList}
                                                                    />
                                                                </Grid>
                                                                <Grid item sx={2}>
                                                                    <IneligibleReasonBarChart
                                                                        /*percentageBarChartData={*/
                                                                        /*    secIneligibleList*/
                                                                        /*}*/
                                                                        percentageBarChartData={renderedSecIneligibleList}
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                        }
                                                    />
                                                </Grid>
                                            </>) : (<></>)

                                        ) : (
                                    renderedFinalIneligibleList?.length > 0 && ineligibleReport !== undefined && extendIneligibleGraphContainer !== true && renderedFinalIneligibleList !== null && renderedIneligibleReport?.length > 0? (<>
                                                <Grid item xs="auto">
                                                    <GraphCard
                                                        graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                                        graphTableData={ineligibleReport}
                                                        graphData={renderedFinalIneligibleList}
                                                        graphName={"Ineligible Reason"}
                                                        graphLegendStyle={"Ineligible Reason X"}
                                                        desiredCardWidth={340}
                                                        desiredCardHeight={400}
                                                        graphLegendComponent={

                                                            <IneligibleReasonBarChart
                                                                /*percentageBarChartData={ //needs to be dynamic list*/

                                                                /*    finalIneligibleList*/


                                                                /*}*/
                                                                percentageBarChartData={renderedFinalIneligibleList}
                                                            />}
                                                    />
                                                </Grid>
                                            </>) : (<></>)



                                        )}
                                    </>) : (<>
                                        <Grid item xs="auto">
                                            <GraphCard
                                                graphLink={null}
                                                graphName={""}
                                                desiredCardWidth={340}
                                                desiredCardHeight={400}
                                                graphComponent={

                                                    <div className="row-no-left-margin">
                                                        <div className="center">
                                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                                <Skeleton height={360} width={308} />
                                                            </SkeletonTheme>
                                                        </div>
                                                    </div>

                                                }
                                                graphLegendComponent={
                                                    <></>
                                                }
                                            />
                                        </Grid>
                                    </>)}
                            {/* </>
                            ) : (<></>)} */}
                        </>
                        <>

                            {/* {showIncompleteChart ? ( */}

                            {renderedIncompleteReport !== undefined ? (
                                renderedIncompleteStatusData?.total > 0 && renderedIncompleteStatusData !== null && renderedIncompleteStatusData !== undefined && renderedIncompleteReport?.length > 0 ? (<>
                                    <Grid item xs="auto" >
                                        <GraphCard
                                            graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                            graphTableData={incompleteReport}
                                            graphData={[
                                                {
                                                    metricTitle: "Invalid Documents",
                                                    metricValue: ISInvalidDocReason,
                                                    metricPercentage: ISInvalidDocPercent
                                                },
                                                {
                                                    metricTitle: "Missing Document(s)",
                                                    metricValue: ISMissingDocReason,
                                                    metricPercentage: ISMissingDocPercent
                                                },
                                                {
                                                    metricTitle: "Document Not Legible",
                                                    metricValue: ISDocNotLegibleReason,
                                                    metricPercentage: ISDocNotLegiblePercent
                                                },
                                            ]}
                                            graphName={"Incomplete Reason"}
                                            desiredCardWidth={340}
                                            desiredCardHeight={400}
                                            
                                            graphComponent={

                                                <PieGraph
                                                    graphValueB={ISMissingDocReason}
                                                    graphValueA={ISInvalidDocReason}
                                                    graphValueC={ISDocNotLegibleReason}
                                                    graphLabelValueB={"Missing Document(s)"}
                                                    graphLabelValueA={"Invalid Documents"}
                                                    graphLabelValueC={"Document Not Legible"}
                                                    metricSubTitle={"Incomplete"}
                                                    graphTitle={"Incomplete Status Graph"}
                                                />
                                            }
                                            graphLegendComponent={

                                                <GraphLegendCard
                                                    graphLegendData={
                                                        [
                                                            {
                                                                metricTitle: "Invalid Documents",
                                                                metricValue: ISInvalidDocReason,
                                                                metricPercentage: ISInvalidDocPercent
                                                            },
                                                            {
                                                                metricTitle: "Missing Document(s)",
                                                                metricValue: ISMissingDocReason,
                                                                metricPercentage: ISMissingDocPercent
                                                            },
                                                            {
                                                                metricTitle: "Document Not Legible",
                                                                metricValue: ISDocNotLegibleReason,
                                                                metricPercentage: ISDocNotLegiblePercent
                                                            },
                                                        ]
                                                    }
                                                />
                                            }
                                        />
                                    </Grid>
                                </>) : (<></>)
                            ) : (<>
                                <Grid item xs="auto" >
                                    <GraphCard
                                        graphLink={null}
                                        graphName={""}
                                        desiredCardWidth={340}
                                        desiredCardHeight={400}
                                        graphComponent={

                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={
                                            <></>
                                        }
                                    />
                                </Grid>
                            </>)}





                            {/*  }) : (<></>)} */}



                            {/*{renderedMemberReport?.length !== undefined ? (*/}
                            {/*    renderedMemberReport.length !== 0 ? (<>*/}
                            {/*        <Grid item xs="auto" >*/}
                            {/*            <GraphCard*/}
                            {/*                graphName={"Employee / Member Status"}*/}
                            {/*                desiredCardWidth={340}*/}
                            {/*                desiredCardHeight={400}*/}
                            {/*                graphHeightPercentage={100}*/}
                            {/*                graphLink={`/report/${String(id)}/${auditName}/graphDetails`}*/}
                            {/*                graphTableData={memberReport}*/}
                            {/*                graphData={[*/}
                            {/*                    {*/}
                            {/*                        metricTitle: "No Response",*/}
                            {/*                        metricValue: MSNoResponseTotal,*/}
                            {/*                        metricPercentage: MSNoResponsePercent,*/}
                            {/*                    },*/}
                            {/*                    {*/}
                            {/*                        metricTitle: "Incomplete",*/}
                            {/*                        metricValue: MSIncompleteTotal,*/}
                            {/*                        metricPercentage: MSIncompletePercent,*/}
                            {/*                    },*/}
                            {/*                    {*/}
                            {/*                        metricTitle: "Review",*/}
                            {/*                        metricValue: MSReviewTotal,*/}
                            {/*                        metricPercentage: MSReviewPercent,*/}
                            {/*                    },*/}
                            {/*                    {*/}
                            {/*                        metricTitle: "Passed",*/}
                            {/*                        metricValue: MSPassedTotal,*/}
                            {/*                        metricPercentage: MSPassedPercent,*/}
                            {/*                    },*/}
                            {/*                    {*/}
                            {/*                        metricTitle: "Suspend",*/}
                            {/*                        metricValue: MSSuspendTotal,*/}
                            {/*                        metricPercentage: MSSuspendPercent,*/}
                            {/*                    },*/}
                            {/*                    {*/}
                            {/*                        metricTitle: "Ineligible",*/}
                            {/*                        metricValue: MSIneligibleTotal,*/}
                            {/*                        metricPercentage: MSIneligiblePercent,*/}
                            {/*                    },*/}

                            {/*                ]}*/}

                            {/*                graphComponent={*/}
                            {/*                    null*/}

                            {/*                }*/}

                            {/*                graphLegendComponent={*/}

                            {/*                    < PercentageBarChart*/}
                            {/*                        percentageBarChartData={*/}
                            {/*                            [*/}
                            {/*                                {*/}
                            {/*                                    metricTitle: "No Response",*/}
                            {/*                                    metricValue: MSNoResponseTotal,*/}
                            {/*                                    metricPercentage: MSNoResponsePercent,*/}
                            {/*                                },*/}
                            {/*                                {*/}
                            {/*                                    metricTitle: "Incomplete",*/}
                            {/*                                    metricValue: MSIncompleteTotal,*/}
                            {/*                                    metricPercentage: MSIncompletePercent,*/}
                            {/*                                },*/}
                            {/*                                {*/}
                            {/*                                    metricTitle: "Review",*/}
                            {/*                                    metricValue: MSReviewTotal,*/}
                            {/*                                    metricPercentage: MSReviewPercent,*/}
                            {/*                                },*/}
                            {/*                                {*/}
                            {/*                                    metricTitle: "Passed",*/}
                            {/*                                    metricValue: MSPassedTotal,*/}
                            {/*                                    metricPercentage: MSPassedPercent,*/}
                            {/*                                },*/}
                            {/*                                {*/}
                            {/*                                    metricTitle: "Suspend",*/}
                            {/*                                    metricValue: MSSuspendTotal,*/}
                            {/*                                    metricPercentage: MSSuspendPercent,*/}
                            {/*                                },*/}
                            {/*                                {*/}
                            {/*                                    metricTitle: "Ineligible",*/}
                            {/*                                    metricValue: MSIneligibleTotal,*/}
                            {/*                                    metricPercentage: MSIneligiblePercent,*/}
                            {/*                                },*/}

                            {/*                            ]*/}
                            {/*                        }*/}
                            {/*                    />}*/}

                            {/*            />*/}
                            {/*        </Grid>*/}
                            {/*    </>) : (<></>)*/}

                            {/*) : (<>*/}
                            {/*    <Grid item xs="auto" >*/}
                            {/*        <GraphCard*/}
                            {/*            graphName={""}*/}
                            {/*            desiredCardWidth={340}*/}
                            {/*            desiredCardHeight={400}*/}
                            {/*            graphHeightPercentage={100}*/}
                            {/*            graphLink={null}*/}
                            {/*            graphComponent={*/}

                            {/*                <div className="row-no-left-margin">*/}
                            {/*                    <div className="center">*/}
                            {/*                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">*/}
                            {/*                            <Skeleton height={360} width={308} />*/}
                            {/*                        </SkeletonTheme>*/}
                            {/*                    </div>*/}
                            {/*                </div>*/}

                            {/*            }*/}
                            {/*            graphLegendComponent={*/}

                            {/*                <></>*/}
                            {/*            }*/}
                            {/*        />*/}
                            {/*    </Grid>*/}
                            {/*</>)}*/}





                            {/*{alertReport?.length > 0 && renderedAlertReport.length !== 0 ? ( */}
                            {renderedAlertReport?.length > 0 ? (
                                <Grid item xs="auto" >
                                    <Badge color="error" badgeContent={renderedAlertReport?.length} invisible={false}> { /* invisible-{reportBadge} */}
                                        <GraphCard
                                            graphName={"Alert"}
                                            desiredCardWidth={340}
                                            desiredCardHeight={100}
                                            graphHeightPercentage={0}
                                            graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                            graphTableData={alertReport}
                                            graphData={alertReport}

                                            graphComponent={
                                                null

                                            }
                                            graphLegendComponent={
                                                null
                                            }
                                        />
                                    </Badge>
                                </Grid>) : (<>

                                </>)}





                        </>

                    </Grid>
                    <Grid container spacing={1} sx={{ mt: 1, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>

                    </Grid>
                </Container>
                <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

                </Container>
            </Box>
            <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
                {/* //MOBILE LAYOUT/COMPONENTS*/}

                {/*  MOBILE PAGE TITLES/HEADINGS*/}
                <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>
                    <div className="row-no-left-margin">
                        <div className="row-left">

                            {promiseIsLoading === false ? (<>
                                <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> Dashboard</Typography>

                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={126} />
                                </SkeletonTheme>
                            </>)}
                        </div>
                        <div className="row-right">

                            {promiseIsLoading === false ? (<>

                                <Tooltip title="Export Graph Data as a CSV">
                                    <Badge badgeContent={alertNoteCount} color="error" invisible={reportBadge}>
                                        <Button variant="contained"
                                            disableElevation sx={{
                                                width: '8VW', height: '8VW',
                                                bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', boxShadow: 0,
                                                ':hover': {
                                                    bgcolor: '#dddddd', // theme.palette.primary.main
                                                    color: '#ffffff',
                                                    boxShadow: 0,
                                                },


                                            }}
                                            style={{ textTransform: 'none' }}
                                            onClick={() => { debouncefetchCSV(); }} >
                                            <Typography fontWeight="600" fontSize="18px" >Export</Typography>
                                        </Button>
                                    </Badge>
                                </Tooltip>
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={72} />
                                </SkeletonTheme>
                            </>)}
                        </div>


                    </div>
                </Container>

                <Container sx={{ display: { xs: 'block', md: 'none' } }}>
                    <div className="row-no-left-margin">
                        <div className="row-left">
                            {promiseIsLoading === false ? (<>
                                {availableClientAuditEndDates?.length !== 0 ? (<><AuditEndDateMenu /></>) : (<></>)}
                            </>) : (<>
                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                    <Skeleton height={36} width={72} />
                                </SkeletonTheme>
                            </>)}

                        </div>
                    </div>
                    {/* Pinned Claims Audits - MOBILE */}
                    <Grid container spacing={1} sx={{ mt: 5, mr: 1, display: { xs: 'block', md: 'none' } }}>

                    </Grid>
                </Container>

                <Container sx={{ display: { xs: 'block', md: 'none' } }}>
                    {/* Pinned Claims Audits - MOBILE */}
                    <Grid container spacing={1} sx={{ mt: 2, mr: 1, display: { xs: 'block', md: 'none' } }}>
                        <Grid item xs={12} sx={{ pl: 0 }}>
                            {responseRateData !== undefined ? (
                                familyCardData?.length > 0 && (familyCardData[3]?.RRGrandTotal !== 0 || familyCardData[3]?.RRNoResponseTotal !== 0 || familyCardData[3]?.RRRespondedTotal !== 0) ? (
                                <GraphCard
                                    graphName={"Response Rate"}
                                    desiredCardWidth={'84VW'}
                                    desiredCardHeight={400}
                                    graphLink={null}
                                    graphComponent={
                                        <div className="row-no-margin">
                                            <div className="center">
                                                <PieGraph
                                                    mobileGraph={true}
                                                    graphValueA={RRRespondedTotal}
                                                    graphValueB={RRNoResponseTotal}
                                                    graphValueC={0}
                                                    graphLabelValueA={"Responded"}
                                                    graphLabelValueB={"No Response"}
                                                    metricSubTitle={'Response Rate'}
                                                    alternateCenterMetricPercent={RRRespondedPercent}
                                                    graphTitle={`Response Rate Graph - ${RRRespondedPercent} Total Percent Responded`}
                                                />
                                            </div>
                                        </div>

                                    }
                                    graphLegendComponent={

                                        <GraphLegendCard
                                            graphLegendData={
                                                [
                                                    {
                                                        metricTitle: "Responded",
                                                        metricValue: RRRespondedTotal,
                                                        metricPercentage: RRRespondedPercent,
                                                    },
                                                    {
                                                        metricTitle: "No Response",
                                                        metricValue: RRNoResponseTotal,
                                                        metricPercentage: RRNoResponsePercent,
                                                    },

                                                ]
                                            }
                                        />
                                    }
                                    />
                                ) : (<></>)
                                ) : (<>

                                    <GraphCard
                                        graphName={""}
                                        graphLink={null}
                                        desiredCardWidth={'84VW'}
                                        desiredCardHeight={400}
                                        graphComponent={
                                            <div className="row-no-left-margin">
                                                <div className="center">
                                                    <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                        <Skeleton height={360} width={308} />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>

                                        }
                                        graphLegendComponent={

                                            <></>
                                        }
                                    />



                                </>)}
                        </Grid>

                        {employeeStatusData != undefined ? (
                            renderedFamilyReport.length !== 0 ? (<>
                                <Grid item xs={12} sx={{ pl: 0 }}>
                                    <GraphCard
                                        graphName={"Family Status"}
                                        desiredCardWidth={'84VW'}
                                        desiredCardHeight={400}
                                        graphTableData={familyReport}
                                        graphData={[
                                            {
                                                metricTitle: "Complete",
                                                metricValue: ESCompleteTotal,
                                                metricPercentage: ESCompletePercent,
                                            },
                                            {
                                                metricTitle: "No Response",
                                                metricValue: ESNoResponseTotal,
                                                metricPercentage: ESNoResponsePercent,
                                            },
                                            {
                                                metricTitle: "Incomplete",
                                                metricValue: ESIncompleteTotal,
                                                metricPercentage: ESIncompletePercent,
                                            },
                                        ]}
                                        graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                        graphComponent={
                                            <div className="row-no-margin">
                                                <div className="center">
                                                    <PieGraph
                                                        mobileGraph={true}
                                                        graphValueA={ESCompleteTotal}
                                                        graphValueB={ESNoResponseTotal}
                                                        graphValueC={ESIncompleteTotal}
                                                        graphLabelValueA={"Complete"}
                                                        graphLabelValueB={"No Response"}
                                                        graphLabelValueC={"Incomplete"}
                                                        metricSubTitle={'Families'}
                                                        graphTitle={`Employee Status Graph - ${ESCompleteTotal} Complete employees out of ${ESCompleteTotal + ESNoResponseTotal + ESIncompleteTotal} Total Employees`}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        graphLegendComponent={
                                            <GraphLegendCard
                                                graphLegendData={
                                                    [
                                                        {
                                                            metricTitle: "Complete",
                                                            metricValue: ESCompleteTotal,
                                                            metricPercentage: ESCompletePercent,
                                                        },
                                                        {
                                                            metricTitle: "No Response",
                                                            metricValue: ESNoResponseTotal,
                                                            metricPercentage: ESNoResponsePercent,
                                                        },
                                                        {
                                                            metricTitle: "Incomplete",
                                                            metricValue: ESIncompleteTotal,
                                                            metricPercentage: ESIncompletePercent,
                                                        },

                                                    ]
                                                }
                                            />

                                        }
                                    />
                                </Grid>
                            </>) : (<>

                            </>)
                        ) : (<>
                            <Grid item xs={12} sx={{ pl: 0 }}>
                                <GraphCard
                                    graphName={""}
                                    graphLink={null}
                                    desiredCardWidth={'84VW'}
                                    desiredCardHeight={400}
                                    graphComponent={

                                        <div className="row-no-left-margin">
                                            <div className="center">
                                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                    <Skeleton height={360} width={308} />
                                                </SkeletonTheme>
                                            </div>
                                        </div>

                                    }
                                    graphLegendComponent={
                                        <></>
                                    }
                                />
                            </Grid>

                        </>)}




                        {dependentStatusData != undefined ? (
                            renderedDependentReport.length !== 0 ? (<>
                                <Grid item xs={12} sx={{ pl: 0 }}>
                                    <GraphCard
                                        graphName={"Dependent Status"}
                                        desiredCardWidth={'84VW'}
                                        desiredCardHeight={400}
                                        graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                        graphTableData={dependentReport}
                                        graphData={[
                                            {
                                                metricTitle: "No Response",
                                                metricValue: DSNoResponseTotal,
                                                metricPercentage: DSNoResponsePercent,
                                            },
                                            {
                                                metricTitle: "Incomplete",
                                                metricValue: DSIncompleteTotal,
                                                metricPercentage: DSIncompletePercent,
                                            },
                                            {
                                                metricTitle: "Review",
                                                metricValue: DSReviewTotal,
                                                metricPercentage: DSReviewPercent,
                                            },
                                            {
                                                metricTitle: "Passed",
                                                metricValue: DSPassedTotal,
                                                metricPercentage: DSPassedPercent,
                                            },
                                            {
                                                metricTitle: "Suspend",
                                                metricValue: DSSuspendTotal,
                                                metricPercentage: DsSuspendPercent,
                                            },
                                            {
                                                metricTitle: "Ineligible",
                                                metricValue: DSIneligibleTotal,
                                                metricPercentage: DSIneligiblePercent,
                                            },
                                        ]}
                                        graphLegendComponent={
                                            <PercentageBarChart
                                                percentageBarChartData={
                                                    [
                                                        {
                                                            metricTitle: "No Response",
                                                            metricValue: DSNoResponseTotal,
                                                            metricPercentage: DSNoResponsePercent,
                                                        },
                                                        {
                                                            metricTitle: "Incomplete",
                                                            metricValue: DSIncompleteTotal,
                                                            metricPercentage: DSIncompletePercent,
                                                        },
                                                        {
                                                            metricTitle: "Review",
                                                            metricValue: DSReviewTotal,
                                                            metricPercentage: DSReviewPercent,
                                                        },
                                                        {
                                                            metricTitle: "Passed",
                                                            metricValue: DSPassedTotal,
                                                            metricPercentage: DSPassedPercent,
                                                        },
                                                        {
                                                            metricTitle: "Suspend",
                                                            metricValue: DSSuspendTotal,
                                                            metricPercentage: DsSuspendPercent,
                                                        },
                                                        {
                                                            metricTitle: "Ineligible",
                                                            metricValue: DSIneligibleTotal,
                                                            metricPercentage: DSIneligiblePercent,
                                                        },

                                                    ]
                                                }
                                            />
                                        }
                                    />
                                </Grid>
                            </>) : (<></>)
                        ) : (<>
                            <Grid item xs={12} sx={{ pl: 0 }}>
                                <GraphCard
                                    graphName={""}
                                    desiredCardWidth={'84VW'}
                                    desiredCardHeight={400}
                                    graphLink={null}
                                    graphComponent={
                                        <div className="row-no-left-margin">
                                            <div className="center">
                                                <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
                                                    <Skeleton height={360} width={308} />
                                                </SkeletonTheme>
                                            </div>
                                        </div>
                                    }
                                    graphLegendComponent={
                                        <></>
                                    }
                                />
                            </Grid>
                        </>)}


                        <>
                            {showIneligibleChart ? (
                                <>
                                    {renderedFinalIneligibleList != undefined ? (<>
                                        {extendIneligibleGraphContainer ? (
                                            renderedIneligibleReport.length !== 0 ? (<>
                                                <Grid item xs={12} sx={{ pl: 0 }}>
                                                    <GraphCard
                                                        graphTableData={ineligibleReport}
                                                        graphName={"Ineligible Reason"}
                                                        graphLegendStyle={"Ineligible Reason XY"}
                                                        graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                                        graphData={renderedFinalIneligibleList}
                                                        desiredCardWidth={"84VW"}
                                                        desiredCardHeight={400}
                                                        graphLegendComponent={
                                                            <IneligibleReasonBarChart
                                                                percentageBarChartData={ //needs to be dynamic list

                                                                    renderedFinalIneligibleList


                                                                }
                                                            />

                                                        }
                                                    />
                                                </Grid>
                                            </>) : (<></>)

                                        ) : (
                                            renderedIneligibleReport.length !== 0 ? (<>
                                                <Grid item xs={12} sx={{ pl: 0 }}>
                                                    <GraphCard
                                                        graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                                        graphTableData={ineligibleReport}
                                                        graphData={renderedFinalIneligibleList}
                                                        graphName={"Ineligible Reason"}
                                                        graphLegendStyle={"Ineligible Reason X"}
                                                        desiredCardWidth={"84VW"}
                                                        desiredCardHeight={400}
                                                        graphLegendComponent={

                                                            <IneligibleReasonBarChart
                                                                percentageBarChartData={ //needs to be dynamic list

                                                                    renderedFinalIneligibleList


                                                                }
                                                            />}
                                                    />
                                                </Grid>
                                            </>) : (<></>)



                                        )}
                                    </>) : (<>
                                        <Grid item xs="auto">
                                            <GraphCard
                                                graphLink={null}
                                                graphName={""}
                                                desiredCardWidth={340}
                                                desiredCardHeight={400}
                                                graphComponent={

                                                    <div className="row-no-left-margin">
                                                        <div className="center">
                                                            <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                                <Skeleton height={360} width={308} />
                                                            </SkeletonTheme>
                                                        </div>
                                                    </div>

                                                }
                                                graphLegendComponent={
                                                    <></>
                                                }
                                            />
                                        </Grid>
                                    </>)}
                                </>
                            ) : (<></>)}

                        </>
                        <>
                            {showIncompleteChart ? (


                                renderedIncompleteReport?.length != undefined ? (
                                    renderedIncompleteReport.length !== 0 ? (<>
                                        <Grid item xs={12} sx={{ pl: 0 }}>
                                            <GraphCard
                                                graphName={"Incomplete Reason"}
                                                desiredCardWidth={"84VW"}
                                                desiredCardHeight={400}
                                                graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                                graphTableData={incompleteReport}
                                                graphData={[
                                                   
                                                    {
                                                        metricTitle: "Invalid Documents",
                                                        metricValue: ISInvalidDocReason,
                                                        metricPercentage: ISInvalidDocPercent
                                                    },
                                                    {
                                                        metricTitle: "Missing Document(s)",
                                                        metricValue: ISMissingDocReason,
                                                        metricPercentage: ISMissingDocPercent
                                                    },
                                                    {
                                                        metricTitle: "Document Not Legible",
                                                        metricValue: ISDocNotLegibleReason,
                                                        metricPercentage: ISDocNotLegiblePercent
                                                    },
                                                ]}
                                                graphComponent={

                                                    <PieGraph
                                                        graphValueB={ISMissingDocReason}
                                                        graphValueA={ISInvalidDocReason}
                                                        graphValueC={ISDocNotLegibleReason}
                                                        graphLabelValueB={"Missing Document(s)"}
                                                        graphLabelValueA={"Invalid Documents"}
                                                        graphLabelValueC={"Document Not Legible"}
                                                        metricSubTitle={"Incomplete"}
                                                        graphTitle={"Incomplete Status Graph"}
                                                    />
                                                }
                                                graphLegendComponent={

                                                    <GraphLegendCard
                                                        graphLegendData={
                                                            [
                                                              
                                                                {
                                                                    metricTitle: "Invalid Documents",
                                                                    metricValue: ISInvalidDocReason,
                                                                    metricPercentage: ISInvalidDocPercent
                                                                },
                                                                {
                                                                    metricTitle: "Missing Document(s)",
                                                                    metricValue: ISMissingDocReason,
                                                                    metricPercentage: ISMissingDocPercent
                                                                },
                                                                {
                                                                    metricTitle: "Document Not Legible",
                                                                    metricValue: ISDocNotLegibleReason,
                                                                    metricPercentage: ISDocNotLegiblePercent
                                                                },
                                                            ]
                                                        }
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </>) : (<></>)
                                ) : (<>
                                    <Grid item xs={12} sx={{ pl: 0 }}>
                                        <GraphCard
                                            graphName={""}
                                            desiredCardWidth={"84VW"}
                                            desiredCardHeight={400}
                                            graphComponent={

                                                <div className="row-no-left-margin">
                                                    <div className="center">
                                                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
                                                            <Skeleton height={360} width={308} />
                                                        </SkeletonTheme>
                                                    </div>
                                                </div>

                                            }
                                            graphLegendComponent={
                                                <></>
                                            }
                                        />
                                    </Grid>
                                </>)


                            ) : (<></>)}

                        </>


                        {/*{employeeStatusData != undefined ? (*/}
                        {/*    renderedMemberReport.length !== 0 ? (<>*/}
                        {/*        <Grid item xs={12} sx={{ pl: 0 }}>*/}
                        {/*            <GraphCard*/}
                        {/*                graphName={"Employee / Member Status"}*/}
                        {/*                desiredCardWidth={'84VW'}*/}
                        {/*                desiredCardHeight={400}*/}
                        {/*                graphLink={`/report/${String(id)}/${auditName}/graphDetails`}*/}
                        {/*                graphTableData={memberReport}*/}
                        {/*                graphData={[*/}
                        {/*                    {*/}
                        {/*                        metricTitle: "No Response",*/}
                        {/*                        metricValue: MSNoResponseTotal,*/}
                        {/*                        metricPercentage: MSNoResponsePercent,*/}
                        {/*                    },*/}
                        {/*                    {*/}
                        {/*                        metricTitle: "Incomplete",*/}
                        {/*                        metricValue: MSIncompleteTotal,*/}
                        {/*                        metricPercentage: MSIncompletePercent,*/}
                        {/*                    },*/}
                        {/*                    {*/}
                        {/*                        metricTitle: "Review",*/}
                        {/*                        metricValue: MSReviewTotal,*/}
                        {/*                        metricPercentage: MSReviewPercent,*/}
                        {/*                    },*/}
                        {/*                    {*/}
                        {/*                        metricTitle: "Passed",*/}
                        {/*                        metricValue: MSPassedTotal,*/}
                        {/*                        metricPercentage: MSPassedPercent,*/}
                        {/*                    },*/}
                        {/*                    {*/}
                        {/*                        metricTitle: "Suspend",*/}
                        {/*                        metricValue: MSSuspendTotal,*/}
                        {/*                        metricPercentage: MSSuspendPercent,*/}
                        {/*                    },*/}
                        {/*                    {*/}
                        {/*                        metricTitle: "Ineligible",*/}
                        {/*                        metricValue: MSIneligibleTotal,*/}
                        {/*                        metricPercentage: MSIneligiblePercent,*/}
                        {/*                    },*/}

                        {/*                ]}*/}

                        {/*                graphLegendComponent={*/}
                        {/*                    <PercentageBarChart*/}
                        {/*                        percentageBarChartData={*/}
                        {/*                            [*/}
                        {/*                                {*/}
                        {/*                                    metricTitle: "No Response",*/}
                        {/*                                    metricValue: MSNoResponseTotal,*/}
                        {/*                                    metricPercentage: MSNoResponsePercent,*/}
                        {/*                                },*/}
                        {/*                                {*/}
                        {/*                                    metricTitle: "Incomplete",*/}
                        {/*                                    metricValue: MSIncompleteTotal,*/}
                        {/*                                    metricPercentage: MSIncompletePercent,*/}
                        {/*                                },*/}
                        {/*                                {*/}
                        {/*                                    metricTitle: "Review",*/}
                        {/*                                    metricValue: MSReviewTotal,*/}
                        {/*                                    metricPercentage: MSReviewPercent,*/}
                        {/*                                },*/}
                        {/*                                {*/}
                        {/*                                    metricTitle: "Passed",*/}
                        {/*                                    metricValue: MSPassedTotal,*/}
                        {/*                                    metricPercentage: MSPassedPercent,*/}
                        {/*                                },*/}
                        {/*                                {*/}
                        {/*                                    metricTitle: "Suspend",*/}
                        {/*                                    metricValue: MSSuspendTotal,*/}
                        {/*                                    metricPercentage: MSSuspendPercent,*/}
                        {/*                                },*/}
                        {/*                                {*/}
                        {/*                                    metricTitle: "Ineligible",*/}
                        {/*                                    metricValue: MSIneligibleTotal,*/}
                        {/*                                    metricPercentage: MSIneligiblePercent,*/}
                        {/*                                },*/}

                        {/*                            ]*/}
                        {/*                        }*/}
                        {/*                    />*/}
                        {/*                }*/}
                        {/*            />*/}
                        {/*        </Grid>*/}
                        {/*    </>) : (<></>)*/}
                        {/*) : (<>*/}
                        {/*    <Grid item xs={12} sx={{ pl: 0 }}>*/}
                        {/*        <GraphCard*/}
                        {/*            graphName={""}*/}
                        {/*            desiredCardWidth={'84VW'}*/}
                        {/*            desiredCardHeight={400}*/}
                        {/*            graphLink={null}*/}
                        {/*            graphComponent={*/}
                        {/*                <div className="row-no-left-margin">*/}
                        {/*                    <div className="center">*/}
                        {/*                        <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">*/}
                        {/*                            <Skeleton height={360} width={308} />*/}
                        {/*                        </SkeletonTheme>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            }*/}
                        {/*            graphLegendComponent={*/}
                        {/*                <></>*/}
                        {/*            }*/}
                        {/*        />*/}
                        {/*    </Grid>*/}
                        {/*</>)}*/}



                        {alertReport?.length !== 0 && renderedAlertReport.length !== 0 ? (
                            <Grid item xs={12} sx={{ pl: 0 }}>
                                <Badge color="error" badgeContent={alertNoteCount} invisible={reportBadge}>
                                    <GraphCard
                                        graphName={"Alert"}
                                        desiredCardWidth={"84VW"}
                                        desiredCardHeight={100}
                                        graphHeightPercentage={0}
                                        graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
                                        graphTableData={alertReport}
                                        graphData={alertReport}

                                        graphComponent={
                                            null

                                        }
                                        graphLegendComponent={
                                            null
                                        }
                                    />
                                </Badge>
                            </Grid>
                        ) : (<>

                        </>)}


                    </Grid>

                </Container>
            </Box>
            <ToastContainer
                limit={1}
                rtl={false}

            />
        </>
    )

   // return (
   //     <>
   //         <Box component="main" sx={{ bgcolor: '#FAFAFA', width: '100VW', minHeight: '100VH', height: 'fit-content', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: '0', mr: 0, display: { xs: 'none', md: 'block', xl: 'block' } }} >
                
   //             {/*  DESKTOP PAGE TITLES/HEADINGS */}
   //             <Container sx={{ mt: 4, ml: 0, display: { xs: 'none', md: 'block' } }}>
   //                 <div className="row-no-left-margin" style={{ width: "82.5VW" }}>
   //                     <div className="row-left">

   //                         {promiseIsLoading === false ? (<>
   //                             <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}>Dashboard</Typography>
   //                             {availableClientAuditEndDates?.length !== 0 ? (<><AuditEndDateMenu/></>) : (<></>) }
   //                         </>) : (<>
   //                             <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                 <Skeleton height={36} width={126} />
   //                             </SkeletonTheme>
   //                         </>)}
   //                     </div>
   //                     <div className="row-right">
                           
   //                         {promiseIsLoading === false  ? (<>

   //                             <Tooltip title="Export Graph Data as a CSV">
   //                                {/* <Badge color="error" badgeContent={alertNoteCount} invisible={reportBadge}>*/}
   //                                 <Button

   //                                     variant="contained"
   //                                     disableElevation sx={{
   //                                         width: '4VW', height: '3VW',
   //                                         bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', boxShadow: 0,
   //                                         ':hover': {
   //                                             bgcolor: '#dddddd', // theme.palette.primary.main
   //                                             color: '#ffffff',
   //                                             boxShadow: 0,
   //                                         },
   //                                     }} onClick={() => { debouncefetchCSV(); }}
   //                                     style={{ textTransform: 'none' }}
   //                                     disabled={isReportRendering}
                                      
   //                                 >
   //                                     <Typography fontWeight="600" fontSize="18px" >Export</Typography>
   //                                 </Button>
   //                               {/*  </Badge>*/}
   //                             </Tooltip>
   //                         </>) : (<>
   //                             <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                 <Skeleton height={36} width={72} />
   //                             </SkeletonTheme>
   //                         </>)}
   //                     </div>
   //                 </div>
   //             </Container >
         

   //             {/* Project Cards - DESKTOP  */}
   //             <Container sx={{ ml: 0, }}>
   //                 <Grid container spacing={3} sx={{ mt: 0, mb: 0, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                       

   //                 </Grid>
   //             </Container>

   //             <Container sx={{ ml: 0 }}>
   //                 <Grid container spacing={3} sx={{ mt: 0.5, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
   //                     <Grid item xs="auto" >

   //                         {/*{responseRateData !== undefined && renderedResponseRateData !== undefined ? (*/}
   //                         { familyCardData?.length > 0 && familyCardData !== undefined && (familyCardData[3]?.RRGrandTotal !== 0 || familyCardData[3]?.RRNoResponseTotal !== 0 || familyCardData[3]?.RRRespondedTotal !== 0) ? ( 
   //                             <GraphCard
   //                                 graphName={"Response Rate"}
   //                                 desiredCardWidth={340}
   //                                 desiredCardHeight={400}
   //                                 graphLink={null}
   //                                 graphData={null}
   //                                 graphComponent={

   //                                     <PieGraph
   //                                         graphValueA={familyCardData[3]?.RRRespondedTotal}
   //                                         graphValueB={familyCardData[3]?.RRNoResponseTotal}
   //                                         graphValueC={0}
   //                                         graphLabelValueA={"Responded"}
   //                                         graphLabelValueB={"No Response"}
   //                                         metricSubTitle={'Response Rate'}
   //                                         alternateCenterMetricPercent={familyCardData[3]?.RRRespondedPercent}
   //                                         graphTitle={`Response Rate Graph - ${familyCardData[3]?.RRRespondedPercent} Total Percent Responded`}
   //                                     />


   //                                 }
   //                                 graphLegendComponent={
   //                                     /*responseRateData !== undefined && renderedResponseRateData !== undefined ? (*/
   //                                 familyCardData?.length > 0 && familyCardData !== undefined && (familyCardData[3]?.RRGrandTotal !== 0 || familyCardData[3]?.RRNoResponseTotal !== 0 || familyCardData[3]?.RRRespondedTotal !== 0) ? (
   //                                         <GraphLegendCard
   //                                             /*
   //                                             graphLegendData={
   //                                                 [
   //                                                     {
   //                                                         metricTitle: "Responded",
   //                                                         metricValue: familyCardData[3]?.RRRespondedTotal,
   //                                                         metricPercentage: familyCardData[3]?.RRRespondedPercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "No Response",
   //                                                         metricValue: familyCardData[3]?.RRNoResponseTotal,
   //                                                         metricPercentage: familyCardData[3]?.RRNoResponsePercent,
   //                                                     },
   //                                                 ]
   //                                             }
   //                                             */

   //                                             graphLegendData={familyCardData[1]}
   //                                         />) : (<></>)
   //                                 }
   //                             />) : (<>
   //                                 <GraphCard
   //                                     graphName={""}
   //                                     desiredCardWidth={340}
   //                                     desiredCardHeight={400}
   //                                     graphLink={null}
   //                                     graphComponent={

   //                                         <div className="row-no-left-margin">
   //                                             <div className="center">
   //                                                 <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                                     <Skeleton height={360} width={308} />
   //                                                 </SkeletonTheme>
   //                                             </div>
   //                                         </div>

   //                                     }
   //                                     graphLegendComponent={

   //                                         <></>
   //                                     }
   //                                 />
   //                             </>)}

   //                     </Grid>
                       
   //                     {employeeStatusData != undefined ? (<>
   //                              <Grid item xs="auto" >
   //                             <GraphCard
   //                                 graphTableData={familyReport}
   //                                 graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                 graphName={"Family Status"}
   //                                 desiredCardWidth={340}
   //                                 desiredCardHeight={400}
   //                                 graphData={[
   //                                     {
   //                                         metricTitle: "Complete",
   //                                         metricValue: ESCompleteTotal,
   //                                         metricPercentage: ESCompletePercent,
   //                                     },
   //                                     {
   //                                         metricTitle: "No Response",
   //                                         metricValue: ESNoResponseTotal,
   //                                         metricPercentage: ESNoResponsePercent,
   //                                     },
   //                                     {
   //                                         metricTitle: "Incomplete",
   //                                         metricValue: ESIncompleteTotal,
   //                                         metricPercentage: ESIncompletePercent,
   //                                     },
   //                                 ]}
   //                                 graphComponent={

   //                                     <PieGraph
   //                                         graphValueA={ESCompleteTotal}
   //                                         graphValueB={ESNoResponseTotal}
   //                                         graphValueC={ESIncompleteTotal}
   //                                         graphLabelValueA={"Complete"}
   //                                         graphLabelValueB={"No Response"}
   //                                         graphLabelValueC={"Incomplete"}
   //                                         metricSubTitle={'Families'}
   //                                         graphTitle={`Employee Status Graph - ${ESCompleteTotal} Complete employees out of ${ESCompleteTotal + ESNoResponseTotal + ESIncompleteTotal} Total Employees`}
   //                                     />
   //                                 }
   //                                 graphLegendComponent={

   //                                     <GraphLegendCard
   //                                         graphLegendData={
   //                                             [
   //                                                 {
   //                                                     metricTitle: "Complete",
   //                                                     metricValue: ESCompleteTotal,
   //                                                     metricPercentage: ESCompletePercent,
   //                                                 },
   //                                                 {
   //                                                     metricTitle: "No Response",
   //                                                     metricValue: ESNoResponseTotal,
   //                                                     metricPercentage: ESNoResponsePercent,
   //                                                 },
   //                                                 {
   //                                                     metricTitle: "Incomplete",
   //                                                     metricValue: ESIncompleteTotal,
   //                                                     metricPercentage: ESIncompletePercent,
   //                                                 },
   //                                             ]
   //                                         }
   //                                     />
   //                                 }
   //                             />
   //                         </Grid>
   //                             </>) : (<>
   //                                 <GraphCard
   //                                     graphLink={null}
   //                                     graphName={""}
   //                                     desiredCardWidth={340}
   //                                     desiredCardHeight={400}
   //                                     graphComponent={

   //                                         <div className="row-no-left-margin">
   //                                             <div className="center">
   //                                                 <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
   //                                                     <Skeleton height={360} width={308} />
   //                                                 </SkeletonTheme>
   //                                             </div>
   //                                         </div>

   //                                     }
   //                                     graphLegendComponent={
   //                                         <></>
   //                                     }
   //                                 />
                       
   //                             </>)}
                           
   //                     </Grid>

   //                     <Grid item xs="auto">
   //                         {dependentStatusData != undefined ? (
   //                             <GraphCard
   //                                 graphName={"Dependent Status"}
   //                                 graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                 graphTableData={dependentReport}
   //                                 graphData={[
   //                                     {
   //                                         metricTitle: "No Response",
   //                                         metricValue: DSNoResponseTotal,
   //                                         metricPercentage: DSNoResponsePercent,
   //                                     },
   //                                     {
   //                                         metricTitle: "Incomplete",
   //                                         metricValue: DSIncompleteTotal,
   //                                         metricPercentage: DSIncompletePercent,
   //                                     },
   //                                     {
   //                                         metricTitle: "Review",
   //                                         metricValue: DSReviewTotal,
   //                                         metricPercentage: DSReviewPercent,
   //                                     },
   //                                     {
   //                                         metricTitle: "Passed",
   //                                         metricValue: DSPassedTotal,
   //                                         metricPercentage: DSPassedPercent,
   //                                     },
   //                                     {
   //                                         metricTitle: "Suspend",
   //                                         metricValue: DSSuspendTotal,
   //                                         metricPercentage: DsSuspendPercent,
   //                                     },
   //                                     {
   //                                         metricTitle: "Ineligible",
   //                                         metricValue: DSIneligibleTotal,
   //                                         metricPercentage: DSIneligiblePercent,
   //                                     },

   //                                         ]}
   //                                         desiredCardWidth={340}
   //                                         desiredCardHeight={400}
   //                                         graphLegendComponent={

   //                                             <PercentageBarChart
   //                                                 percentageBarChartData={
   //                                                     [
   //                                                         {
   //                                                             metricTitle: "No Response",
   //                                                             metricValue: DSNoResponseTotal,
   //                                                             metricPercentage: DSNoResponsePercent,
   //                                                         },
   //                                                         {
   //                                                             metricTitle: "Incomplete",
   //                                                             metricValue: DSIncompleteTotal,
   //                                                             metricPercentage: DSIncompletePercent,
   //                                                         },
   //                                                         {
   //                                                             metricTitle: "Review",
   //                                                             metricValue: DSReviewTotal,
   //                                                             metricPercentage: DSReviewPercent,
   //                                                         },
   //                                                         {
   //                                                             metricTitle: "Passed",
   //                                                             metricValue: DSPassedTotal,
   //                                                             metricPercentage: DSPassedPercent,
   //                                                         },
   //                                                         {
   //                                                             metricTitle: "Suspend",
   //                                                             metricValue: DSSuspendTotal,
   //                                                             metricPercentage: DsSuspendPercent,
   //                                                         },
   //                                                         {
   //                                                             metricTitle: "Ineligible",
   //                                                             metricValue: DSIneligibleTotal,
   //                                                             metricPercentage: DSIneligiblePercent,
   //                                                         },

   //                                                     ]
   //                                                 }
   //                                             />}
   //                                     />
                             
                         
   //                     ) : (<>
                           
   //                                 <GraphCard
   //                                     graphLink={null}
   //                                     graphName={""}
   //                                     desiredCardWidth={340}
   //                                     desiredCardHeight={400}
   //                                     graphComponent={

   //                                         <div className="row-no-left-margin">
   //                                             <div className="center">
   //                                                 <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
   //                                                     <Skeleton height={360} width={308} />
   //                                                 </SkeletonTheme>
   //                                             </div>
   //                                         </div>

   //                                     }
   //                                     graphLegendComponent={
   //                                         <></>
   //                                     }
   //                                 />
                              
   //                     </>)}
   //                     </Grid>
                          
                       
   //                     <>
   //                     {showIneligibleChart ? (
   //                     <>
   //                     {ineligibleStatusData != undefined ? (<>
   //                         {extendIneligibleGraphContainer ? (
   //                             <Grid item xs={7.5}>
   //                                             <IneligibleExtendedGraphCard
   //                                                 graphTableData={ineligibleReport}
   //                                                 graphName={"Ineligible Reason"}
   //                                                 graphLegendStyle={"Ineligible Reason XY"}
   //                                                 graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                                 graphData={finalIneligibleList}
   //                                         desiredCardWidth={708}
   //                                         desiredCardHeight={400}
   //                                         graphLegendComponent={
   //                                             <Grid container columnSpacing={{ xs: 2 }} justifyContent="center">
   //                                                 <Grid item sx={2}>
   //                                                     <IneligibleReasonBarChart
   //                                                         percentageBarChartData={
   //                                                             firstIneligibleList
   //                                                         }
   //                                                     />
   //                                                 </Grid>
   //                                                 <Grid item sx={2}>
   //                                                     <IneligibleReasonBarChart
   //                                                         percentageBarChartData={
   //                                                             secIneligibleList
   //                                                         }
   //                                                     />
   //                                                 </Grid>
   //                                             </Grid>

   //                                         }
   //                                     />
   //                             </Grid> 
   //                         ) : (
   //                                 <Grid item xs="auto">
   //                                                 <GraphCard
   //                                                     graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                                     graphTableData={ineligibleReport}
   //                                                     graphData={finalIneligibleList}
   //                                                     graphName={"Ineligible Reason"}
   //                                                     graphLegendStyle={"Ineligible Reason X"}
   //                                             desiredCardWidth={340}
   //                                             desiredCardHeight={400}
   //                                             graphLegendComponent={

   //                                                 <IneligibleReasonBarChart
   //                                                     percentageBarChartData={ //needs to be dynamic list

   //                                                         finalIneligibleList


   //                                                     }
   //                                                 />}
   //                                         />
   //                                 </Grid>
                            
                            
                            
   //                         )}
   //                     </>) : (<>
   //                     <Grid item xs="auto">
   //                                             <GraphCard
   //                                                 graphLink={null}
   //                             graphName={""}
   //                             desiredCardWidth={340}
   //                             desiredCardHeight={400}
   //                             graphComponent={

   //                             <div className="row-no-left-margin">
   //                                 <div className="center">
   //                                     <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
   //                                         <Skeleton height={360} width={308} />
   //                                     </SkeletonTheme>
   //                                 </div>
   //                             </div>

   //                             }
   //                             graphLegendComponent={
   //                              <></>
   //                             }
   //                         />               
   //                     </Grid>
   //                     </>)}
   //                     </>
   //                     ) : (<></>)}
   //                     </>
   //                     <>
   //                     <Grid item xs="auto" >
   //                     {showIncompleteChart ? (
   //                         <>
   //                             {incompleteStatusData != undefined ? (
   //                                     <GraphCard
   //                                         graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                         graphTableData={incompleteReport}
   //                                         graphData={[
   //                                             {
   //                                                 metricTitle: "Missing Document(s)",
   //                                                 metricValue: ISMissingDocReason,
   //                                                 metricPercentage: ISMissingDocPercent
   //                                             },
   //                                             {
   //                                                 metricTitle: "Invalid Documents",
   //                                                 metricValue: ISInvalidDocReason,
   //                                                 metricPercentage: ISInvalidDocPercent
   //                                             },
   //                                             {
   //                                                 metricTitle: "Document Not Legible",
   //                                                 metricValue: ISDocNotLegibleReason,
   //                                                 metricPercentage: ISDocNotLegiblePercent
   //                                             },
   //                                         ]}
   //                                     graphName={"Incomplete Reason"}
   //                                     desiredCardWidth={340}
   //                                     desiredCardHeight={400}
   //                                     graphComponent={

   //                                                 <PieGraph
   //                                                     graphValueA={ISMissingDocReason}
   //                                                     graphValueB={ISInvalidDocReason}
   //                                                     graphValueC={ISDocNotLegibleReason}
   //                                                     graphLabelValueA={"Missing Document(s)"}
   //                                                     graphLabelValueB={"Invalid Documents"}
   //                                                     graphLabelValueC={"Document Not Legible"}
   //                                                     metricSubTitle={"Incomplete"}
   //                                                     graphTitle={"Incomplete Status Graph"}
   //                                                 />
   //                                             }
   //                                             graphLegendComponent={

   //                                                 <GraphLegendCard
   //                                                     graphLegendData={
   //                                                         [
   //                                                             {
   //                                                                 metricTitle: "Missing Document(s)",
   //                                                                 metricValue: ISMissingDocReason,
   //                                                                 metricPercentage: ISMissingDocPercent
   //                                                             },
   //                                                             {
   //                                                                 metricTitle: "Invalid Documents",
   //                                                                 metricValue: ISInvalidDocReason,
   //                                                                 metricPercentage: ISInvalidDocPercent
   //                                                             },
   //                                                             {
   //                                                                 metricTitle: "Document Not Legible",
   //                                                                 metricValue: ISDocNotLegibleReason,
   //                                                                 metricPercentage: ISDocNotLegiblePercent
   //                                                             },
   //                                                         ]
   //                                                     }
   //                                                 />
   //                                             }
   //                                         />
                                       
   //                                     ) : (<></>)
   //                                     </>) : (<>
                                            
   //                                         <GraphCard
   //                                             graphLink={null}
   //                                         graphName={""}
   //                                         desiredCardWidth={340}
   //                                         desiredCardHeight={400}
   //                                         graphComponent={

   //                                             <div className="row-no-left-margin">
   //                                                 <div className="center">
   //                                                     <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
   //                                                         <Skeleton height={360} width={308} />
   //                                                     </SkeletonTheme>
   //                                                 </div>
   //                                             </div>

   //                                         }
   //                                         graphLegendComponent={
   //                                             <></>
   //                                         }
   //                                     />
                                           
   //                                 </>)

                              
   //                                </Grid> 


   //                         //) : (<></>)}

                          

   //                             {employeeStatusData != undefined? (
   //                             renderedMemberReport.length !== 0 ? (<>
   //                                 <Grid item xs="auto" >
   //                                     <GraphCard
   //                                         graphName={"Employee / Member Status"}
   //                                         desiredCardWidth={340}
   //                                         desiredCardHeight={400}
   //                                         graphHeightPercentage={100}
   //                                         graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                         graphTableData={memberReport}
   //                                         graphData={[
   //                                             {
   //                                                 metricTitle: "No Response",
   //                                                 metricValue: MSNoResponseTotal,
   //                                                 metricPercentage: MSNoResponsePercent,
   //                                             },
   //                                             {
   //                                                 metricTitle: "Incomplete",
   //                                                 metricValue: MSIncompleteTotal,
   //                                                 metricPercentage: MSIncompletePercent,
   //                                             },
   //                                             {
   //                                                 metricTitle: "Review",
   //                                                 metricValue: MSReviewTotal,
   //                                                 metricPercentage: MSReviewPercent,
   //                                             },
   //                                             {
   //                                                 metricTitle: "Passed",
   //                                                 metricValue: MSPassedTotal,
   //                                                 metricPercentage: MSPassedPercent,
   //                                             },
   //                                             {
   //                                                 metricTitle: "Suspend",
   //                                                 metricValue: MSSuspendTotal,
   //                                                 metricPercentage: MSSuspendPercent,
   //                                             },
   //                                             {
   //                                                 metricTitle: "Ineligible",
   //                                                 metricValue: MSIneligibleTotal,
   //                                                 metricPercentage: MSIneligiblePercent,
   //                                             },

   //                                         ]}

   //                                         graphComponent={
   //                                             null

   //                                         }

   //                                         graphLegendComponent={

   //                                             < PercentageBarChart
   //                                                 percentageBarChartData={
   //                                                     [
   //                                                         {
   //                                                             metricTitle: "No Response",
   //                                                             metricValue: MSNoResponseTotal,
   //                                                             metricPercentage: MSNoResponsePercent,
   //                                                         },
   //                                                         {
   //                                                             metricTitle: "Incomplete",
   //                                                             metricValue: MSIncompleteTotal,
   //                                                             metricPercentage: MSIncompletePercent,
   //                                                         },
   //                                                         {
   //                                                             metricTitle: "Review",
   //                                                             metricValue: MSReviewTotal,
   //                                                             metricPercentage: MSReviewPercent,
   //                                                         },
   //                                                         {
   //                                                             metricTitle: "Passed",
   //                                                             metricValue: MSPassedTotal,
   //                                                             metricPercentage: MSPassedPercent,
   //                                                         },
   //                                                         {
   //                                                             metricTitle: "Suspend",
   //                                                             metricValue: MSSuspendTotal,
   //                                                             metricPercentage: MSSuspendPercent,
   //                                                         },
   //                                                         {
   //                                                             metricTitle: "Ineligible",
   //                                                             metricValue: MSIneligibleTotal,
   //                                                             metricPercentage: MSIneligiblePercent,
   //                                                         },

   //                                                     ]
   //                                                 }
   //                                             />}

   //                                     />
   //                                 </Grid>
   //                                 </>) : (<></>)
                                  
   //                                 ) : (<>
   //                                     <Grid item xs="auto" >
   //                                 <GraphCard
   //                                     graphName={""}
   //                                     desiredCardWidth={340}
   //                                     desiredCardHeight={400}
   //                                     graphHeightPercentage={100}
   //                                     graphLink={null}
   //                                     graphComponent={

   //                                         <div className="row-no-left-margin">
   //                                             <div className="center">
   //                                                 <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                                     <Skeleton height={360} width={308} />
   //                                                 </SkeletonTheme>
   //                                             </div>
   //                                         </div>

   //                                     }
   //                                     graphLegendComponent={

   //                                         <></>
   //                                     }
   //                                         />
   //                                     </Grid>
   //                             </>)}

                         

   //                             {alertReport?.length > 0 ? (
   //                                 <Badge color="error" badgeContent={alertNoteCount} invisible={reportBadge}>
   //                                     <GraphCard
   //                                         graphName={"Alert"}
   //                                         desiredCardWidth={340}
   //                                         desiredCardHeight={100}
   //                                         graphHeightPercentage={0}
   //                                         graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                         graphTableData={alertReport}
   //                                         graphData={alertReport}

   //                                         graphComponent={
   //                                             null

   //                                         }
   //                                         graphLegendComponent={
   //                                             null
   //                                         }
   //                                     />
   //                                 </Badge>
   //                             </Grid>) : (<>

   //                                 </>)}

                            

                           

   //                     </>
                      
   //                 </Grid>
   //                 <Grid container spacing={1} sx={{ mt: 1, mb: 2, display: { xs: 'none', md: 'flex', xl: 'flex' } }}>
                      
   //                 </Grid>
   //             </Container>
   //             <Container sx={{ ml: 0, mr: 0, mt: 2.5, height: '4rem' }}>

   //             </Container>
   //         </Box>
   //         <Box component="main" sx={{ width: '100%', height: '100VH', flexGrow: 1, paddingTop: 1, mt: 2.5, ml: 0.5, display: { xs: 'block', md: 'none', xl: 'none' } }}  >
   //             {/* //MOBILE LAYOUT/COMPONENTS*/}

   //             {/*  MOBILE PAGE TITLES/HEADINGS*/}
   //             <Container sx={{ mt: 9, ml: 0.75, mr: 2, display: { xs: 'block', md: 'none' } }}>
   //                 <div className="row-no-left-margin">
   //                     <div className="row-left">
                          
   //                         {promiseIsLoading === false ? (<>
   //                             <Typography textAlign="left" component="h1" gutterBottom={true} fontWeight="600" fontSize="1.5em" sx={{ mt: 0, }}> Dashboard</Typography>
                               
   //                         </>) : (<>
   //                             <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                 <Skeleton height={36} width={126} />
   //                             </SkeletonTheme>
   //                         </>)}
   //                     </div>
   //                     <div className="row-right">
                           
   //                         {promiseIsLoading === false ? (<>

   //                             <Tooltip title="Export Graph Data as a CSV">
   //                                 <Badge badgeContent={alertNoteCount} color="error" invisible={reportBadge}>
   //                                     <Button variant="contained"
   //                                         disableElevation sx={{
   //                                             width: '8VW', height: '8VW',
   //                                             bgcolor: 'transparent', color: '#262626', border: '2px solid #dddddd', boxShadow: 0,
   //                                             ':hover': {
   //                                                 bgcolor: '#dddddd', // theme.palette.primary.main
   //                                                 color: '#ffffff',
   //                                                 boxShadow: 0,
   //                                             },
                              
                                             
   //                                         }}
   //                                         style={{ textTransform: 'none' }}
   //                                         onClick={() => { debouncefetchCSV(); }} >
   //                                     <Typography fontWeight="600" fontSize="18px" >Export</Typography>
   //                                     </Button>
   //                                     </Badge>
   //                             </Tooltip>
   //                         </>) : (<>
   //                             <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                 <Skeleton height={36} width={72} />
   //                             </SkeletonTheme>
   //                         </>)}
   //                     </div>

                        
   //                 </div>
   //             </Container>

   //             <Container sx={{ display: { xs: 'block', md: 'none' } }}>
   //                 <div className="row-no-left-margin">
   //                     <div className="row-left">
   //                         {promiseIsLoading === false ? (<>
   //                             {availableClientAuditEndDates?.length !== 0 ? (<><AuditEndDateMenu /></>) : (<></>)}
   //                         </>) : (<>
   //                                 <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                     <Skeleton height={36} width={72} />
   //                                 </SkeletonTheme>
   //                         </>)}
                           
   //                     </div>
   //                 </div>
   //                 {/* Pinned Claims Audits - MOBILE */}
   //                 <Grid container spacing={1} sx={{ mt: 5, mr: 1, display: { xs: 'block', md: 'none' } }}>
                       
   //                 </Grid>
   //             </Container>

   //             <Container sx={{ display: { xs: 'block', md: 'none' } }}>
   //                 {/* Pinned Claims Audits - MOBILE */}
   //                 <Grid container spacing={1} sx={{ mt: 2, mr: 1, display: { xs: 'block', md: 'none' } }}>
   //                     <Grid item xs={12} sx={{ pl:0 }}>
   //                         {responseRateData != undefined ? (
   //                             <GraphCard
   //                                 graphName={"Response Rate"}
   //                                 desiredCardWidth={'84VW'}
   //                                 desiredCardHeight={400}
   //                                 graphLink={null}
   //                                 graphComponent={
   //                                     <div className="row-no-margin">
   //                                         <div className="center">
   //                                             <PieGraph
   //                                                 mobileGraph={true}
   //                                                 graphValueA={RRRespondedTotal}
   //                                                 graphValueB={RRNoResponseTotal}
   //                                                 graphValueC={0}
   //                                                 graphLabelValueA={"Responded"}
   //                                                 graphLabelValueB={"No Response"}
   //                                                 metricSubTitle={'Response Rate'}
   //                                                 alternateCenterMetricPercent={RRRespondedPercent}
   //                                                 graphTitle={`Response Rate Graph - ${RRRespondedPercent} Total Percent Responded`}
   //                                             />
   //                                         </div>
   //                                     </div>

   //                                 }
   //                                 graphLegendComponent={

   //                                     <GraphLegendCard
   //                                         graphLegendData={
   //                                             [
   //                                                 {
   //                                                     metricTitle: "Responded",
   //                                                     metricValue: RRRespondedTotal,
   //                                                     metricPercentage: RRRespondedPercent,
   //                                                 },
   //                                                 {
   //                                                     metricTitle: "No Response",
   //                                                     metricValue: RRNoResponseTotal,
   //                                                     metricPercentage: RRNoResponsePercent,
   //                                                 },
                                                   
   //                                             ]
   //                                         }
   //                                     />
   //                                 }
   //                             />) : (<>

   //                                 <GraphCard
   //                                     graphName={""}
   //                                     graphLink={null}
   //                                     desiredCardWidth={'84VW'}
   //                                     desiredCardHeight={400}
   //                                     graphComponent={
   //                                         <div className="row-no-left-margin">
   //                                             <div className="center">
   //                                                 <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                                     <Skeleton height={360} width={308} />
   //                                                 </SkeletonTheme>
   //                                             </div>
   //                                         </div>

   //                                     }
   //                                     graphLegendComponent={

   //                                         <></>
   //                                     }
   //                                 />



   //                             </>)}
   //                     </Grid>
   //                     <Grid item xs={12} sx={{ pl: 0 }}>
   //                         {employeeStatusData != undefined ? (
   //                             <GraphCard
   //                                 graphName={"Family Status"}
   //                                 desiredCardWidth={'84VW'}
   //                                 desiredCardHeight={400}
   //                                 graphTableData={familyReport}
   //                                 graphData={[
   //                                     {
   //                                         metricTitle: "Complete",
   //                                         metricValue: ESCompleteTotal,
   //                                         metricPercentage: ESCompletePercent,
   //                                     },
   //                                     {
   //                                         metricTitle: "No Response",
   //                                         metricValue: ESNoResponseTotal,
   //                                         metricPercentage: ESNoResponsePercent,
   //                                     },
   //                                     {
   //                                         metricTitle: "Incomplete",
   //                                         metricValue: ESIncompleteTotal,
   //                                         metricPercentage: ESIncompletePercent,
   //                                     },
   //                                 ]}
   //                                 graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                 graphComponent={
   //                                     <div className="row-no-margin">
   //                                         <div className="center">
   //                                             <PieGraph
   //                                                 mobileGraph={true}
   //                                                 graphValueA={ESCompleteTotal}
   //                                                 graphValueB={ESNoResponseTotal}
   //                                                 graphValueC={ESIncompleteTotal}
   //                                                 graphLabelValueA={"Complete"}
   //                                                 graphLabelValueB={"No Response"}
   //                                                 graphLabelValueC={"Incomplete"}
   //                                                 metricSubTitle={'Families'}
   //                                                 graphTitle={`Employee Status Graph - ${ESCompleteTotal} Complete employees out of ${ESCompleteTotal + ESNoResponseTotal + ESIncompleteTotal} Total Employees`}
   //                                             />
   //                                         </div>
   //                                     </div>
   //                                 }
   //                                 graphLegendComponent={
   //                                     <GraphLegendCard
   //                                         graphLegendData={
   //                                             [
   //                                                 {
   //                                                     metricTitle: "Complete",
   //                                                     metricValue: ESCompleteTotal,
   //                                                     metricPercentage: ESCompletePercent,
   //                                                 },
   //                                                 {
   //                                                     metricTitle: "No Response",
   //                                                     metricValue: ESNoResponseTotal,
   //                                                     metricPercentage: ESNoResponsePercent,
   //                                                 },
   //                                                 {
   //                                                     metricTitle: "Incomplete",
   //                                                     metricValue: ESIncompleteTotal,
   //                                                     metricPercentage: ESIncompletePercent,
   //                                                 },
                                                  
   //                                             ]
   //                                         }
   //                                     />

   //                                     }
   //                                 />
   //                             </Grid>
   //                             </>) : (<>
                                       
   //                                 </>)
   //                            ) : (<>
   //                             <Grid item xs={12} sx={{ pl: 0 }}>
   //                                 <GraphCard
   //                                     graphName={""}
   //                                     graphLink={null}
   //                                     desiredCardWidth={'84VW'}
   //                                     desiredCardHeight={400}
   //                                     graphComponent={

   //                                         <div className="row-no-left-margin">
   //                                             <div className="center">
   //                                                 <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                                     <Skeleton height={360} width={308} />
   //                                                 </SkeletonTheme>
   //                                             </div>
   //                                         </div>

   //                                     }
   //                                     graphLegendComponent={
   //                                         <></>
   //                                     }
   //                                 />
   //                                 </Grid>
                                    
   //                             </>)}
                       


                       
   //                         {dependentStatusData != undefined ? (
   //                             renderedDependentReport.length !== 0 ? (<>
   //                             <Grid item xs={12} sx={{ pl: 0 }}>
   //                                 <GraphCard
   //                                     graphName={"Dependent Status"}
   //                                     desiredCardWidth={'84VW'}
   //                                     desiredCardHeight={400}
   //                                     graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                     graphTableData={dependentReport}
   //                                     graphData={[
   //                                         {
   //                                             metricTitle: "No Response",
   //                                             metricValue: DSNoResponseTotal,
   //                                             metricPercentage: DSNoResponsePercent,
   //                                         },
   //                                         {
   //                                             metricTitle: "Incomplete",
   //                                             metricValue: DSIncompleteTotal,
   //                                             metricPercentage: DSIncompletePercent,
   //                                         },
   //                                         {
   //                                             metricTitle: "Review",
   //                                             metricValue: DSReviewTotal,
   //                                             metricPercentage: DSReviewPercent,
   //                                         },
   //                                         {
   //                                             metricTitle: "Passed",
   //                                             metricValue: DSPassedTotal,
   //                                             metricPercentage: DSPassedPercent,
   //                                         },
   //                                         {
   //                                             metricTitle: "Suspend",
   //                                             metricValue: DSSuspendTotal,
   //                                             metricPercentage: DsSuspendPercent,
   //                                         },
   //                                         {
   //                                             metricTitle: "Ineligible",
   //                                             metricValue: DSIneligibleTotal,
   //                                             metricPercentage: DSIneligiblePercent,
   //                                         },
   //                                     ]}
   //                                     graphLegendComponent={
   //                                         <PercentageBarChart
   //                                             percentageBarChartData={
   //                                                 [
   //                                                     {
   //                                                         metricTitle: "No Response",
   //                                                         metricValue: DSNoResponseTotal,
   //                                                         metricPercentage: DSNoResponsePercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "Incomplete",
   //                                                         metricValue: DSIncompleteTotal,
   //                                                         metricPercentage: DSIncompletePercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "Review",
   //                                                         metricValue: DSReviewTotal,
   //                                                         metricPercentage: DSReviewPercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "Passed",
   //                                                         metricValue: DSPassedTotal,
   //                                                         metricPercentage: DSPassedPercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "Suspend",
   //                                                         metricValue: DSSuspendTotal,
   //                                                         metricPercentage: DsSuspendPercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "Ineligible",
   //                                                         metricValue: DSIneligibleTotal,
   //                                                         metricPercentage: DSIneligiblePercent,
   //                                                     },

   //                                                 ]
   //                                             }
   //                                         />
   //                                     }
   //                                 />
   //                             </Grid>
   //                             </>) : (<></>)
   //                     ) : (<>
   //                             <Grid item xs={12} sx={{ pl: 0 }}>
   //                                 <GraphCard
   //                                     graphName={""}
   //                                     desiredCardWidth={'84VW'}
   //                                     desiredCardHeight={400}
   //                                     graphLink={null}
   //                                     graphComponent={
   //                                         <div className="row-no-left-margin">
   //                                             <div className="center">
   //                                                 <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                                     <Skeleton height={360} width={308} />
   //                                                 </SkeletonTheme>
   //                                             </div>
   //                                         </div>
   //                                     }
   //                                     graphLegendComponent={
   //                                         <></>
   //                                     }
   //                             />
   //                     </Grid>
   //                             </>)} 
                    

   //                     <>
   //                         {showIneligibleChart ? (
   //                             <>
   //                                 {ineligibleStatusData != undefined ? (<>
   //                                     {extendIneligibleGraphContainer ? (
   //                                         renderedIneligibleReport.length !== 0 ? (<>
   //                                             <Grid item xs={12} sx={{ pl: 0 }}>
   //                                                 <GraphCard
   //                                                     graphTableData={ineligibleReport}
   //                                                     graphName={"Ineligible Reason"}
   //                                                     graphLegendStyle={"Ineligible Reason XY"}
   //                                                     graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                                     graphData={finalIneligibleList}
   //                                                     desiredCardWidth={"84VW"}
   //                                                     desiredCardHeight={400}
   //                                                     graphLegendComponent={
   //                                                         <IneligibleReasonBarChart
   //                                                             percentageBarChartData={ //needs to be dynamic list

   //                                                                 finalIneligibleList


   //                                                             }
   //                                                         />

   //                                                     }
   //                                                 />
   //                                             </Grid>
   //                                         </>) : (<></>)
                                           
   //                                 ) : (
   //                                 renderedIneligibleReport.length !== 0 ? (<>
   //                                     <Grid item xs={12} sx={{ pl: 0 }}>
   //                                         <GraphCard
   //                                             graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                             graphTableData={ineligibleReport}
   //                                             graphData={finalIneligibleList}
   //                                             graphName={"Ineligible Reason"}
   //                                             graphLegendStyle={"Ineligible Reason X"}
   //                                             desiredCardWidth={"84VW"}
   //                                             desiredCardHeight={400}
   //                                             graphLegendComponent={

   //                                                 <IneligibleReasonBarChart
   //                                                     percentageBarChartData={ //needs to be dynamic list

   //                                                         finalIneligibleList


   //                                                     }
   //                                                 />}
   //                                         />
   //                                     </Grid>
   //                                 </>) : (<></>)
                                               


   //                                     )}
   //                                 </>) : (<>
   //                                     <Grid item xs="auto">
   //                                         <GraphCard
   //                                             graphLink={null}
   //                                             graphName={""}
   //                                             desiredCardWidth={340}
   //                                             desiredCardHeight={400}
   //                                             graphComponent={

   //                                                 <div className="row-no-left-margin">
   //                                                     <div className="center">
   //                                                         <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
   //                                                             <Skeleton height={360} width={308} />
   //                                                         </SkeletonTheme>
   //                                                     </div>
   //                                                 </div>

   //                                             }
   //                                             graphLegendComponent={
   //                                                 <></>
   //                                             }
   //                                         />
   //                                     </Grid>
   //                                 </>)}
   //                             </>
   //                         ) : (<></>)}

   //                     </>
   //                     <>
   //                     {showIncompleteChart ? (

                     
   //                                 incompleteStatusData != undefined ? (
   //                                 renderedIncompleteReport.length !== 0 ? (<>
   //                                     <Grid item xs={12} sx={{ pl: 0 }}>
   //                                         <GraphCard
   //                                             graphName={"Incomplete Reason"}
   //                                             desiredCardWidth={"84VW"}
   //                                             desiredCardHeight={400}
   //                                             graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                             graphTableData={incompleteReport}
   //                                             graphData={[
   //                                                 {
   //                                                     metricTitle: "Missing Document(s)",
   //                                                     metricValue: ISMissingDocReason,
   //                                                     metricPercentage: ISMissingDocPercent
   //                                                 },
   //                                                 {
   //                                                     metricTitle: "Invalid Documents",
   //                                                     metricValue: ISInvalidDocReason,
   //                                                     metricPercentage: ISInvalidDocPercent
   //                                                 },
   //                                                 {
   //                                                     metricTitle: "Document Not Legible",
   //                                                     metricValue: ISDocNotLegibleReason,
   //                                                     metricPercentage: ISDocNotLegiblePercent
   //                                                 },
   //                                             ]}
   //                                             graphComponent={

   //                                                 <PieGraph
   //                                                     graphValueA={ISMissingDocReason}
   //                                                     graphValueB={ISInvalidDocReason}
   //                                                     graphValueC={ISDocNotLegibleReason}
   //                                                     graphLabelValueA={"Missing Document(s)"}
   //                                                     graphLabelValueB={"Invalid Documents"}
   //                                                     graphLabelValueC={"Document Not Legible"}
   //                                                     metricSubTitle={"Incomplete"}
   //                                                     graphTitle={"Incomplete Status Graph"}
   //                                                 />
   //                                             }
   //                                             graphLegendComponent={

   //                                                 <GraphLegendCard
   //                                                     graphLegendData={
   //                                                         [
   //                                                             {
   //                                                                 metricTitle: "Missing Document(s)",
   //                                                                 metricValue: ISMissingDocReason,
   //                                                                 metricPercentage: ISMissingDocPercent
   //                                                             },
   //                                                             {
   //                                                                 metricTitle: "Invalid Documents",
   //                                                                 metricValue: ISInvalidDocReason,
   //                                                                 metricPercentage: ISInvalidDocPercent
   //                                                             },
   //                                                             {
   //                                                                 metricTitle: "Document Not Legible",
   //                                                                 metricValue: ISDocNotLegibleReason,
   //                                                                 metricPercentage: ISDocNotLegiblePercent
   //                                                             },
   //                                                         ]
   //                                                     }
   //                                                 />
   //                                             }
   //                                         />
   //                                     </Grid>
   //                                     </>) : (<></>)
   //                             ) : (<>
   //                                     <Grid item xs={12} sx={{ pl: 0 }}>
   //                                 <GraphCard
   //                                     graphName={""}
   //                                     desiredCardWidth={"84VW"}
   //                                     desiredCardHeight={400}
   //                                     graphComponent={

   //                                         <div className="row-no-left-margin">
   //                                             <div className="center">
   //                                                 <SkeletonTheme baseColor="#EFF6FF" highlightColor="#ffffff">
   //                                                     <Skeleton height={360} width={308} />
   //                                                 </SkeletonTheme>
   //                                             </div>
   //                                         </div>

   //                                     }
   //                                     graphLegendComponent={
   //                                         <></>
   //                                     }
   //                                 />
   //                             </Grid>
   //                             </>)

                       
   //                         ) : (<></>)}

   //                     </>

                       
   //                         {employeeStatusData != undefined ? (
   //                         renderedMemberReport.length !== 0 ? (<>
   //                             <Grid item xs={12} sx={{ pl: 0 }}>
   //                                 <GraphCard
   //                                     graphName={"Employee / Member Status"}
   //                                     desiredCardWidth={'84VW'}
   //                                     desiredCardHeight={400}
   //                                     graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                     graphTableData={memberReport}
   //                                     graphData={[
   //                                         {
   //                                             metricTitle: "No Response",
   //                                             metricValue: MSNoResponseTotal,
   //                                             metricPercentage: MSNoResponsePercent,
   //                                         },
   //                                         {
   //                                             metricTitle: "Incomplete",
   //                                             metricValue: MSIncompleteTotal,
   //                                             metricPercentage: MSIncompletePercent,
   //                                         },
   //                                         {
   //                                             metricTitle: "Review",
   //                                             metricValue: MSReviewTotal,
   //                                             metricPercentage: MSReviewPercent,
   //                                         },
   //                                         {
   //                                             metricTitle: "Passed",
   //                                             metricValue: MSPassedTotal,
   //                                             metricPercentage: MSPassedPercent,
   //                                         },
   //                                         {
   //                                             metricTitle: "Suspend",
   //                                             metricValue: MSSuspendTotal,
   //                                             metricPercentage: MSSuspendPercent,
   //                                         },
   //                                         {
   //                                             metricTitle: "Ineligible",
   //                                             metricValue: MSIneligibleTotal,
   //                                             metricPercentage: MSIneligiblePercent,
   //                                         },

   //                                     ]}

   //                                     graphLegendComponent={
   //                                         <PercentageBarChart
   //                                             percentageBarChartData={
   //                                                 [
   //                                                     {
   //                                                         metricTitle: "No Response",
   //                                                         metricValue: MSNoResponseTotal,
   //                                                         metricPercentage: MSNoResponsePercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "Incomplete",
   //                                                         metricValue: MSIncompleteTotal,
   //                                                         metricPercentage: MSIncompletePercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "Review",
   //                                                         metricValue: MSReviewTotal,
   //                                                         metricPercentage: MSReviewPercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "Passed",
   //                                                         metricValue: MSPassedTotal,
   //                                                         metricPercentage: MSPassedPercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "Suspend",
   //                                                         metricValue: MSSuspendTotal,
   //                                                         metricPercentage: MSSuspendPercent,
   //                                                     },
   //                                                     {
   //                                                         metricTitle: "Ineligible",
   //                                                         metricValue: MSIneligibleTotal,
   //                                                         metricPercentage: MSIneligiblePercent,
   //                                                     },

   //                                                 ]
   //                                             }
   //                                         />
   //                                     }
   //                                 />
   //                             </Grid>
   //                             </>) : (<></>)
   //                     ) : (<>
   //                             <Grid item xs={12} sx={{ pl: 0 }}>
   //                                 <GraphCard
   //                                     graphName={""}
   //                                     desiredCardWidth={'84VW'}
   //                                     desiredCardHeight={400}
   //                                     graphLink={null}
   //                                     graphComponent={
   //                                         <div className="row-no-left-margin">
   //                                             <div className="center">
   //                                                 <SkeletonTheme baseColor="#EFF6FF" highlightColor="ffffff">
   //                                                     <Skeleton height={360} width={308} />
   //                                                 </SkeletonTheme>
   //                                             </div>
   //                                         </div>
   //                                     }
   //                                     graphLegendComponent={
   //                                         <></>
   //                                     }
   //                         />
   //                     </Grid>
   //                             </>)}
                       

                     
   //                     {alertReport?.length !== 0 && renderedAlertReport.length !== 0 ? (
   //                         <Grid item xs={12} sx={{ pl: 0 }}>
   //                             <Badge color="error" badgeContent={alertNoteCount} invisible={reportBadge}>
   //                                 <GraphCard
   //                                     graphName={"Alert"}
   //                                     desiredCardWidth={"84VW"}
   //                                     desiredCardHeight={100}
   //                                     graphHeightPercentage={0}
   //                                     graphLink={`/report/${String(id)}/${auditName}/graphDetails`}
   //                                     graphTableData={alertReport}
   //                                     graphData={alertReport}

   //                                     graphComponent={
   //                                         null

   //                                     }
   //                                     graphLegendComponent={
   //                                         null
   //                                     }
   //                                 />
   //                             </Badge>
   //                         </Grid>
		 //) : (<>
                               
   //                         </>)}
                       

   //                 </Grid>
               
   //             </Container>
   //         </Box>
   //         <ToastContainer
   //             limit={1}
   //             rtl={false}

   //         />
   //     </>
   // )
}

export default Project;
