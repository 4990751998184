
import React from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Avatar from '@mui/material/Avatar';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Link } from "react-router-dom";

import { BsDot } from "react-icons/bs";

import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    width: '100%',
    borderRadius: 5,
    
}));


function PercentageBarChart({ percentageBarChartData }) {

    const percentageBarTheme = createTheme({
        palette: {
            passed: { //bmi blue

                //main: '#1D4ED8',
                main: '#0059A8',
            },
            suspend: {
               
                main: '#60A5FA',
            },
            ineligble: {
               
                //main: '#2DD4BF',
                main: '#fce587',
            },
            review: { //supsend color

                //main: '#99F6E4',
                main: '#60A5FA',
            },
            incomplete: {
               
                //main: '#FBBF24',
                main: '#F6BE00'
            },
            noresponse: {
             
                //main: '#FDE68A',
                main: '#B6EFE4',
            },
        },
    });


    

    function getMetricBackground(name) {
        if (name !== undefined) {
           
            if (name == "Passed") {//bmi blue
                return (
                    '#EFF6FF'
                );
            }
            if (name == "Suspend") {
                return (
                    '#EFF6FF'
                );
            }

            if (name == "Ineligible") {
                return (
                    //'#F0FDFA'
                    '#FFFBEB'
                );
            }
            if (name == 'Review') {//supsend color
                return (
                    //'#F0FDFA'
                    '#EFF6FF'
                );
            }
            if (name == 'Incomplete') {
                return (
                    '#FFFBEB'
                );
            }
            if (name == 'No Response') { //change to "#F0FDFA"
                return (
                    //'#FFFBEB'
                    '#F0FDFA'
                );
            }

        }
    }

   



    return (
        <>
            {percentageBarChartData?.map((item) => {
                return (
                    <div key={item?.metricTitle} >
                      
                        <div className="row-no-left-margin ">
                           
                            <div className="row-left">
                               

                                <div>

                                 
                                    <Typography fontWeight="500" fontSize="14px" sx={{ color: '#262626' }}> {item?.metricTitle}</Typography>
                                </div>


                            </div>
                            <div className="row-right">
                              
                                <div>
                                   
                                    <Typography fontWeight="600" fontSize="14px"> {item?.metricValue?.toLocaleString()}</Typography>
                                </div>
                               
                                <div className="halfspacer-margin-left halfquarterspacer-margin-top">
                                 
                                    <Typography fontWeight="400" fontSize="12px" >  ({item?.metricPercentage}%)</Typography>
                                </div>
                            </div>



                        </div>
                        <div className="row-no-margin spacer-margin-bottom">
                            <ThemeProvider theme={percentageBarTheme}>
                                <Box sx={{ flexGrow: 1 }}>
                                    {item?.metricTitle == 'No Response' ? (<>
                                        <BorderLinearProgress aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`} color="noresponse" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == 'Incomplete' ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="incomplete" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == 'Review' ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="review" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == 'Passed' ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="passed" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == "Suspend" ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="suspend" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}

                                    {item?.metricTitle == "Ineligible" ? (<>
                                        <BorderLinearProgress
                                            aria-label={`${item?.metricTitle} Status - ${item?.metricPercentage} Percent - ${item?.metricValue} Out of Total Dependents`}
                                            color="ineligble" variant="determinate" value={Number(item?.metricPercentage)} sx={{ bgcolor: getMetricBackground(item?.metricTitle) }} />
                                    </>) : (<></>)}
                                </Box>
                            </ThemeProvider>
                        </div>



                    </div>

                )
            })}
        </>
    );
}

export default PercentageBarChart;