
import React from 'react';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import { MdStar, MdStarBorder } from "react-icons/md";
import IconButton from '@mui/material/IconButton';
import Container from "@mui/material/Container";
import Avatar from '@mui/material/Avatar';

import { Link } from "react-router-dom";

import { BsDot } from "react-icons/bs";


function GraphLegendCard({ graphLegendData }) {

    function getMetricBackground(name) {
        if (name !== undefined) {
           
            if (name == 'Complete' || name == 'Responded' || name == 'Invalid Documents') {
                return (
                    '#23549F'
                );
            }
            if (name == 'No Response' || name == 'Missing Document(s)') {
                return (
                    '#C2EDE5'
                );
            }

            if (name == 'Incomplete' || name == 'Document Not Legible') {
                return (
                    '#EDC041'
                );
            }

        }
    }

    



    return (
        <>
            {graphLegendData?.map((item) => {
                return (
                    
                    <div className="row-no-left-margin border-bottom" key={`${item?.metricTitle}:${item?.metricValue}:${item?.metricPercentage}%`}>
                        <Box sx={{ bgcolor: getMetricBackground(item?.metricTitle), borderRadius: '4px', height: '15px', width: '15px', mr: '0.25rem', mt: '0.1rem' }} variant="rounded" />
                        <div className="row-left">

                            <div>
                                <Typography fontWeight="600" fontSize="14px" sx={{ color: '#262626' }}> {item?.metricTitle}</Typography>
                            </div>


                        </div>
                        <div className="row-right">
                            
                            <div>
                                <Typography fontWeight="600" fontSize="14px"> {item?.metricValue?.toLocaleString()}</Typography>
                            </div>
                         
                            <div className="halfspacer-margin-left halfquarterspacer-margin-top">
                                <Typography fontWeight="400" fontSize="12px" >  ({item?.metricPercentage}%)</Typography>
                            </div>
                        </div>



                    </div>
                )
            })}
        </>
    );
}

export default GraphLegendCard;