import React, { useState, useEffect, useContext } from 'react';
import { loginRequest, b2cPolicies } from '../authConfig';
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { InteractionType, InteractionStatus, InteractionRequiredAuthError } from '@azure/msal-browser';
import { RoutesContext, PermissionsContext, GraphDetailsContext, ProjectDashboardContext, RenderedProjectDashboardContext } from "../App";


function useFetchGraphDetails() {
    const { instance, accounts } = useMsal();

    //const [id, setId] = useState();
    const [familyData, setFamilyData] = useState([]);
    const [dependentData, setDependentData] = useState([]);
    const [alertData, setAlertData] = useState([]);
    const [ineligibleData, setIneligibleData] = useState([]);
    const [incompleteData, setIncompleteData] = useState([]);
    const [memberData, setMemberData] = useState([]);
    const [auditEndDates, setAuditEndDates] = useState([]);
    const [throwFetchError, setThrowFetchError] = useState(false);
    const [fetchStatus, setFetchStatus] = useState(null);
    const [promiseIsLoading, setPromiseIsLoading] = useState(true);


    /* context for storing promise data */

    const {
        projectId, setProjectId,
        familyReport, setFamilyReport,
        dependentReport, setDependentReport,
        alertReport, setAlertReport,
        ineligibleReport, setIneligibleReport,
        responseRateData, setResponseRateData,
        employeeStatusData, setEmployeeStatusData,
        dependentStatusData, setDependentStatusData,
        ineligibleStatusData, setIneligibleStatusData,
        incompleteStatusData, setIncompleteStatusData,
        incompleteReport, setIncompleteReport,
        finalIneligibleList, setFinalIneligibleList,
        alertNoteCount, setAlertNoteCount,
        firstIneligibleList, setFirstIneligibleList,
        secIneligibleList, setSecIneligibleList,
        extendIneligibleGraphContainer, setExtendIneligibleGraphContainer,
        hideIneligibleChart, setHideIneligibleChart,
        showIneligibleChart, setShowIneligibleChart,
        memberReport, setMemberReport,
        memberStatusData, setMemberStatusData,
        availableClientAuditEndDates, setAvailableClientAuditEndDates, handleUpdateAvailableClientAuditEndDates,
        activeClientAuditEndDates, setActiveClientAuditEndDates, handleUpdateActiveClientAuditEndDates, callFilter, setCallFilter,
        unfilteredFamilyReport, setUnfilteredFamilyReport,
        unfilteredDependentReport, setUnfilteredDependentReport,
        unfilteredAlertReport, setUnfilteredAlertReport,
        unfilteredIneligibleReport, setUnfilteredIneligibleReport,
        unfilteredMemberReport, setUnfilteredMemberReport,
        unfilteredResponseRateData, setUnfilteredResponseRateData,
        unfilteredEmployeeStatusData, setUnfilteredEmployeeStatusData,
        unfilteredDependentStatusData, setUnfilteredDependentStatusData,
        unfilteredIneligibleStatusData, setUnfilteredIneligibleStatusData,
        unfilteredIncompleteStatusData, setUnfilteredIncompleteStatusData,
        unfilteredMemberStatusData, setUnfilteredMemberStatusData,
        unfilteredIncompleteReport, setUnfilteredIncompleteReport,
        unfilteredAlertNoteCount, setUnfilteredAlertNoteCount
    } = useContext(ProjectDashboardContext);

    const {

        renderedFamilyReport, setRenderedFamilyReport,
        renderedDependentReport, setRenderedDependentReport,
        renderedAlertReport, setRenderedAlertReport,
        renderedIneligibleReport, setRenderedIneligibleReport,
        renderedMemberReport, setRenderedMemberReport,
        renderedResponseRateData, setRenderedResponseRateData,
        renderedEmployeeStatusData, setRenderedEmployeeStatusData,
        renderedDependentStatusData, setRenderedDependentStatusData,
        renderedIneligibleStatusData, setRenderedIneligibleStatusData,
        renderedIncompleteStatusData, setRenderedIncompleteStatusData,
        renderedMemberStatusData, setRenderedMemberStatusData,
        renderedIncompleteReport, setRenderedIncompleteReport,
        renderedFinalIneligibleList, setRenderedFinalIneligibleList,
        showRenderedIneligibleChart, setShowRenderedIneligibleChart,
        extendRenderedIneligibleGraphContainer, setExtendRenderedIneligibleGraphContainer,
        renderedAlertNoteCount, setRenderedAlertNoteCount,
        renderedFirstIneligibleList, setRenderedFirstIneligibleList,
        renderedSecIneligibleList, setRenderedSecIneligibleList,
        hideRenderedIneligibleChart, setRenderedHideIneligibleChart,
        filteredAuditDates, setFilteredAuditDates

    } = useContext(RenderedProjectDashboardContext);

    function handleFetchAll(id) {

        setPromiseIsLoading(true);

        const fetchResponseRate = async (token) => {
            let retry = 5;
            let response = null;
            let counter = 1;
            let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
            while (retry > 0) {
                if (retry < 5) {
                    let d = 2000 * counter;
                    await timeout(d);
                }
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetResponseRate', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": parseInt(id) })
                }).then((res) => {

                    if (res.ok) {
                        retry = 0;
                        response = res.json();
                        return;
                        //return res.json();
                    }
                    if (retry > 1) {
                        retry--;
                        counter++;
                        return;
                    }
                    if (retry <= 1) {
                        retry = 0;
                        //failedFetchError(res.status); //throw error 
                        setThrowFetchError(!throwFetchError);
                        setFetchStatus(res.status);
                        return;
                    }

                }).catch((error) => { console.log(error) })
            }

            return response;
        };

        const fetchFamilyStatus = async (token) => {
            let retry = 5;
            let response = null;
            let counter = 1;
            let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
            while (retry > 0) {
                if (retry < 5) {
                    let d = 2000 * counter;
                    await timeout(d);
                }
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetEmployeeStatus', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": parseInt(id) })
                }).then(res => {

                    if (res.ok) {
                        retry = 0;
                        //return res.json();
                        response = res.json();
                        return;
                    }
                    if (retry > 1) {
                        retry--;
                        counter++;
                        return;
                    }
                    if (retry <= 1) {
                        retry = 0;
                        //failedFetchError(res.status); //throw error 
                        setThrowFetchError(!throwFetchError);
                        setFetchStatus(res.status);
                        return;
                    }

                }).catch((error) => { console.log(error) })
            }
            return response;
        };

        const fetchDependentStatus = async (token) => {
            let retry = 5;
            let response = null;
            let counter = 1;
            let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
            while (retry > 0) {
                if (retry < 5) {
                    let d = 2000 * counter; //exponentially delay fetch request
                    await timeout(d);
                }
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetDependentStatus', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": parseInt(id) })
                }).then(res => {

                    if (res.ok) {
                        retry = 0
                        //return res.json();
                        response = res.json();
                        return;
                    }
                    if (retry > 1) {
                        counter++;
                        retry--;
                        return;
                    }
                    if (retry <= 1) {
                        retry = 0;
                        //failedFetchError(res.status); //throw error 
                        setThrowFetchError(!throwFetchError);
                        setFetchStatus(res.status);
                        return;
                    }

                })
                    .catch((error) => { console.log(error) })
            }
            return response;
        };

        const fetchAlertStatus = async (token) => {
            let retry = 5;
            let response = null;
            let counter = 1;
            let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
            while (retry > 0) {
                if (retry < 5) {
                    let d = 2000 * counter;
                    await timeout(d);
                }
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetAlertReportData', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": parseInt(id) })
                }).then(res => {

                    if (res.ok) {
                        retry = 0
                        //return res.json(); 
                        response = res.json();
                        return;
                    }
                    if (retry > 1) {
                        retry--;
                        counter++;
                        return;
                    }
                    if (retry <= 1) {
                        retry = 0;
                        //failedFetchError(res.status); //throw error 
                        setThrowFetchError(!throwFetchError);
                        setFetchStatus(res.status);
                        return;
                    }

                }).catch((error) => { console.log(error) })
            }
            return response;
        };
        const fetchIneligibleStatus = async (token) => {
            let retry = 5;
            let response = null;
            let counter = 1;
            let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
            while (retry > 0) {
                if (retry < 5) {
                    let d = 2000 * counter;
                    await timeout(d);
                }
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIneligibleStatus', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": parseInt(id) })
                }).then(res => {

                    if (res.ok) {
                        retry = 0;
                        response = res.json();
                        return;
                    }
                    if (retry > 1) {
                        retry--;
                        counter++;
                        return;
                    }
                    if (retry <= 1) {
                        retry = 0;
                        //failedFetchError(res.status); //throw error 
                        setThrowFetchError(!throwFetchError);
                        setFetchStatus(res.status);
                        return;
                    }

                }).catch((error) => { console.log(error) })
            }
            return response;
        };

        const fetchIncompleteStatus = async (token) => {
            let retry = 5;
            let response = null;
            let counter = 1;
            let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
            while (retry > 0) {
                if (retry < 5) {
                    let d = 2000 * counter;
                    await timeout(d);
                }
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetIncompleteStatus', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": parseInt(id) })
                }).then(res => {
                    if (res.ok) {
                        retry = 0
                        response = res.json();
                        return;
                    }
                    if (retry > 1) {
                        retry--;
                        counter++;
                        return;
                    }
                    if (retry <= 1) {
                        retry = 0;
                        //failedFetchError(res.status); //throw error 
                        setThrowFetchError(!throwFetchError);
                        setFetchStatus(res.status);
                        return;
                    }


                })
                    .catch((error) => { console.log(error) })
            }


            return response;
        };

        //const fetchMemberStatus = async (token) => {
        //    let retry = 5;
        //    let response = null;
        //    let counter = 1;
        //    let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
        //    while (retry > 0) {
        //        if (retry < 5) {
        //            let d = 2000 * counter;
        //            await timeout(d);
        //        }
        //        await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetMemberStatus', {
        //            method: 'POST',
        //            mode: 'cors',
        //            headers: {
        //                'Content-Type': 'application/json',
        //                'Authorization': 'Bearer ' + token,
        //            },
        //            body: JSON.stringify({ "auditId": parseInt(id) })
        //        }).then(res => {
        //            if (res.ok) {
        //                retry = 0
        //                response = res.json();
        //                return;
        //            }
        //            if (retry > 1) {
        //                retry--;
        //                counter++;
        //                return;
        //            }
        //            if (retry <= 1) {
        //                retry = 0;
        //                //failedFetchError(res.status); //throw error 
        //                setThrowFetchError(!throwFetchError);
        //                setFetchStatus(res.status);
        //                return;
        //            }


        //        })
        //            .catch((error) => { console.log(error) })
        //    }


        //    return response;
        //};

        const fetchAuditEndDates = async (token) => {
            let retry = 5;
            let response = null;
            let counter = 1;
            let timeout = (delay) => new Promise(resolve => setTimeout(resolve, delay));
            while (retry > 0) {
                if (retry < 5) {
                    let d = 2000 * counter;
                    await timeout(d);
                }
                await fetch(process.env.REACT_APP_BACK_URL + '/api/Graphs/GetAuditEndDateData', {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    },
                    body: JSON.stringify({ "auditId": parseInt(id) })
                }).then(res => {
                    if (res.ok) {
                        retry = 0
                        response = res.json();
                        return;
                    }
                    if (retry > 1) {
                        retry--;
                        counter++;
                        return;
                    }
                    if (retry <= 1) {
                        retry = 0;
                        //failedFetchError(res.status); //throw error 
                        setThrowFetchError(!throwFetchError);
                        setFetchStatus(res.status);
                        return;
                    }


                })
                    .catch((error) => { console.log(error) })
            }


            return response;
        };

        const fetchInPromise = async () => {
            if (accounts.length > 0) {
                await instance.acquireTokenSilent({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) })
                    .then(result => {
                        const fetchData = async () => {

                            await Promise.allSettled([


                                fetchFamilyStatus(result.idToken), //result[0]  //result[0]?.value?.employeeGraphDto === result[1], result[0]?.value?.employeStatusReports === result[6]. result[0]?.value?.responseGraphDto === result[0] 
                                fetchDependentStatus(result.idToken), //result[1] //result[1]?.value?.dependentGraphDto === result[2], result[1]?.value?.dependentStatusReports === result[7]
                                fetchAlertStatus(result.idToken), //result[2] //result[2]?.value?.alertReportData === result[3]
                                fetchIneligibleStatus(result.idToken), //result[3] //result[3]?.value?.ineligibleGraphDto === result[4], result[3]?.value?.ineligibleReports === result[8]
                                fetchIncompleteStatus(result.idToken), //result[4] //result[4]?.value?.incompleteGraphDataDto ===  result[5], result[4]?.value?.incompelteReports === result[9]
                                //fetchMemberStatus(result.idToken), //reusult[5] 
                                fetchAuditEndDates(result.idToken), //result[6]


                            ])
                                .then((result) => {
                                    console.log("PROJECT FETCH RESULT inside hook - ", result);

                                    /* data accessible outside of hook */
                                    /* setting data without context */
                                    setFamilyData(result[0]?.value?.employeeStatusReports);
                                    setDependentData(result[1]?.value?.dependentStatusReports);
                                    setAlertData(result[2]?.value?.alertReportData);
                                    setAlertNoteCount(result[2]?.value?.alertReportData?.length);
                                    setIneligibleData(result[3]?.value?.ineligibleReports);
                                    setIncompleteData(result[4]?.value?.incompleteReports);
                                    //setMemberData(result[5]?.value?.memberStatusReports);
                                    setAuditEndDates(result[5]?.value);

                                    /* setting data with context */
                                    /* REPORT DATA */
                                    setFamilyReport(result[0]?.value?.employeeStatusReports);
                                    setDependentReport(result[1].value?.dependentStatusReports);
                                    setAlertReport(result[2]?.value?.alertReportData);
                                    setIneligibleReport(result[3]?.value?.ineligibleReports);
                                    setIncompleteReport(result[4]?.value?.incompleteReports);
                                    //setMemberReport(result[5]?.value?.memberStatusReports);

                                    /* GRAPH DATA */
                                    setResponseRateData(result[0]?.value?.responseGraphDto);
                                    setEmployeeStatusData(result[0]?.value?.employeeGraphDto);
                                    setDependentStatusData(result[1]?.value?.dependentGraphDto);
                                    //setMemberStatusData(result[5]?.value?.memberGraphDto);
                                    /* end stored reports/data */

                                    /* set initial data for rendering and default report exports*/
                                    setRenderedFamilyReport(result[0]?.value?.employeeStatusReports);
                                    setRenderedDependentReport(result[1].value?.dependentStatusReports);
                                    setRenderedAlertReport(result[2]?.value?.alertReportData);
                                    setRenderedIneligibleReport(result[3]?.value?.ineligibleReports);
                                    setRenderedIncompleteReport(result[4]?.value?.incompleteReports);
                                    //setRenderedMemberReport(result[5]?.value?.memberStatusReports);
                                    setRenderedAlertNoteCount(result[2]?.value?.alertReportData?.length);


                                    setRenderedResponseRateData(result[0]?.value?.responseGraphDto);
                                    setRenderedEmployeeStatusData(result[0]?.value?.employeeGraphDto);
                                    setRenderedDependentStatusData(result[1]?.value?.dependentGraphDto);
                                    //setRenderedMemberStatusData(result[5]?.value?.memberGraphDto);
                                    setRenderedIncompleteStatusData(result[4]?.value?.incompleteGraphDataDto);
                                    setRenderedIneligibleStatusData(result[3]?.value?.ineligibleGraphDto);
                                    //filterIneligibleGraphHook.handleIneligibleGraphFiltering(result[3]?.value?.ineligibleReports);
                                    handleUpdateAvailableClientAuditEndDates(result[5]?.value);


                                    /* UNFILTERED REPORT DATA BACKUP */
                                    setUnfilteredFamilyReport(result[0]?.value?.employeeStatusReports);
                                    setUnfilteredDependentReport(result[1].value?.dependentStatusReports);
                                    setUnfilteredAlertReport(result[2]?.value?.alertReportData);
                                    setUnfilteredIneligibleReport(result[3]?.value?.ineligibleReports);
                                    setUnfilteredIncompleteReport(result[4]?.value?.incompleteReports);
                                    //setUnfilteredMemberReport(result[5]?.value?.memberStatusReports);

                                    setPromiseIsLoading(false); //controls audit date menu and export from displaying

                                })
                                .catch((error) => {
                                    console.log('promise error -', error); //promise failed
                                    //abort fetch request here
                                });


                        }

                        fetchData();
                    }).catch((error) => {
                        console.log(error) //token expired/is not valid => user is redirected to login page
                        if (error instanceof InteractionRequiredAuthError) {
                            instance.acquireTokenRedirect({ loginRequest, account: instance.getAllAccounts().find((account) => account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn) });
                        }
                    });
            }
        }

        if (projectId !== id) { //only fetch if stored project has changed
            fetchInPromise();
        }


    }

    return { handleFetchAll, familyData, dependentData, alertData, ineligibleData, incompleteData, memberData, auditEndDates, throwFetchError, fetchStatus, promiseIsLoading };
}
export default useFetchGraphDetails;